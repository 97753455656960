// Third modules
import { useEffect, useRef } from 'react'

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  }, [value])
  return ref.current
}

export default usePrevious
