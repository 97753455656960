import _ from 'lodash/fp.js'
// Third modules
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MODAL_FORGOTTEN_PASSWORD, MODAL_HELP, MODAL_RESET_PASSWORD } from 'connect/constants/modal.jsx'
// Our modules
import { getOpenModal } from 'connect/selectors/ui.jsx'
import { closeModal } from 'connect/slices/ui.jsx'

import ForgottenPasswordModal from './forgottenPassword.jsx'
import HelpModal from './help.jsx'
import ResetPasswordModal from './resetPassword.jsx'

const Modal = () => {
  const dispatch = useDispatch()

  const openModal = useSelector(getOpenModal)
  const modalName = _.get('name', openModal)
  const modalData = _.getOr({}, 'data', openModal)

  const close = useCallback(() => {
    dispatch(closeModal())
  }, [dispatch])

  return (
    openModal && (
      <>
        {modalName === MODAL_FORGOTTEN_PASSWORD && <ForgottenPasswordModal close={close} {...modalData} />}
        {modalName === MODAL_RESET_PASSWORD && <ResetPasswordModal close={close} {...modalData} />}
        {modalName === MODAL_HELP && <HelpModal close={close} {...modalData} />}
      </>
    )
  )
}

export default Modal
