// Third modules
import _ from 'lodash/fp.js'

import axios from 'connect/api/axios.jsx'
// Our modules
import config from 'connect/config/index.js'

export const post = (path, body) => {
  return axios.post(`${config.api_url}/${path}`, body).then(_.get('data'))
}
