// Third modules
import { useEffect, useRef } from 'react'

const useDidUpdate = (callback, dependencies = []) => {
  const hasMounted = useRef(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true
      return
    }

    callback()
  }, dependencies)
}

export default useDidUpdate
