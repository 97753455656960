// Third modules

import { makeComponent } from '@lls/utils'
import {
  ENT_CONNECT_ERROR_CONNECTOR_NOT_SUPPORTED,
  ENT_CONNECT_ERROR_SCHOOL_NOT_PREMIUM,
  ENT_CONNECT_GAR_ERROR_NOT_AVAILABLE,
  ENT_CONNECT_STATIM_ERROR_FAMILY_ACCOUNT,
  ENT_CONNECT_STATIM_ERROR_LOGIN_API,
  ENT_CONNECT_STATIM_ERROR_SCHOOLS_API,
  ENT_CONNECT_STATIM_ERROR_SCHOOL_NOT_FOUND
} from 'connect/constants/ent.jsx'
// Our modules
import cssModule from './errors.module.css'

const ErrorStatimLoginApi = () => (
  <>
    <div>La connexion à EcoleDirecte n'a pas pu aboutir.</div>
    <div>
      Assurez-vous que les identifiants que vous avez saisis sont bien ceux que vous utilisez habituellement pour vous
      connecter sur EcoleDirecte.
    </div>
  </>
)

export const errorCodeToErrorComponentMap = {
  [ENT_CONNECT_GAR_ERROR_NOT_AVAILABLE]: (
    <>
      La connexion n'a pas pu aboutir car votre établissement ne fait pas partie du projet GAR. Si vous pensez qu'il
      s'agit d'une erreur, demandez de l'aide à notre support.
    </>
  ),

  [ENT_CONNECT_ERROR_SCHOOL_NOT_PREMIUM]: (
    <>
      Cet établissement n'est pas abonné à notre offre Premium. Si vous pensez qu'il s'agit d'une erreur, demandez de
      l'aide à notre support.
    </>
  ),

  [ENT_CONNECT_ERROR_CONNECTOR_NOT_SUPPORTED]: (
    <>
      <div>Connecteur non-disponible.</div>
      <div>Attention votre établissement ne semble pas rattaché à cet ENT.</div>
    </>
  ),

  [ENT_CONNECT_STATIM_ERROR_SCHOOL_NOT_FOUND]: (
    <>Votre établissement doit utiliser EcoleDirecte pour vous permettre d'accéder à cette fonctionnalité.</>
  ),

  [ENT_CONNECT_STATIM_ERROR_FAMILY_ACCOUNT]: (
    <>
      <div>Le compte utilisé n'est pas éligible à cette fonctionnalité.</div>
      <div>Veuillez utiliser un compte professeur ou élève pour vous connecter.</div>
    </>
  ),

  [ENT_CONNECT_STATIM_ERROR_LOGIN_API]: <ErrorStatimLoginApi />,
  [ENT_CONNECT_STATIM_ERROR_SCHOOLS_API]: <ErrorStatimLoginApi />
}

export const DefaultError = () => <>La requête n'a pas pu aboutir.</>

export const StyledError = makeComponent('div', cssModule.error)
