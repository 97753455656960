// Third modules
import _ from 'lodash/fp.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'wouter'

import { Button } from '@lls/lls-kit'
import { getWindow } from '@lls/utils'
// Our modules
import config from 'connect/config/index.js'
import { MODAL_HELP } from 'connect/constants/modal.jsx'
import { openModal } from 'connect/slices/ui.jsx'
import { isSetTokenRoute } from 'connect/utils/routes.jsx'

import cssModule from './help.module.css'

export default function Help() {
  const dispatch = useDispatch()
  const [location] = useLocation()
  const locationRef = useRef(location)
  const [isCrispReady, setCrispReady] = useState(false)

  const onHelpClick = useCallback(() => dispatch(openModal({ name: MODAL_HELP })), [dispatch])

  useEffect(() => {
    if (isSetTokenRoute(locationRef.current)) return

    const windowRef = getWindow()
    windowRef.$crisp = []
    windowRef.CRISP_WEBSITE_ID = config.crisp_website_id
    ;(() => {
      const doc = document
      const script = doc.createElement('script')

      script.src = 'https://client.crisp.chat/l.js'
      script.async = 1
      doc.getElementsByTagName('head')[0].appendChild(script)
    })()

    windowRef.CRISP_READY_TRIGGER = () => {
      setCrispReady(true)
    }
  }, [])

  useEffect(() => {
    if (!isCrispReady) return
    const crisp = _.get('$crisp', window)
    try {
      crisp.push(['do', 'chat:show'])
      crisp.push(['config', 'hide:on:mobile', [true]])
    } catch (error) {
      /*eslint-disable-next-line no-console*/
      console.error('error loading crisp', error)
    }
  }, [isCrispReady])

  return (
    <div className={cssModule.helpContainer}>
      <div>
        <p className={cssModule.contactText}>Demandez de l'aide</p>
        <p className={cssModule.contactText}>à notre support !</p>
      </div>
      <img className={cssModule.img} src='/assets/img/purple-arrow.svg' alt='' />
      <div className={cssModule.buttonContainer}>
        <Button className={cssModule.button} theme='gray' icon='questionmarkcircle' onClick={onHelpClick} />
      </div>
    </div>
  )
}
