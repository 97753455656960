import _ from 'lodash/fp.js'
import { useMemo } from 'react'

import { Carousel } from '@lls/lls-kit'
import { formatBooksForCarousel } from 'connect/utils/book.jsx'
import cssModule from './carousel.module.css'

const BookCover = ({ src }) => {
  return (
    <div className={cssModule.bookCover}>
      <img className={cssModule.book3D} src='/assets/img/book-3d.svg' alt='' />
      <img className={cssModule.coverImg} src={src} alt='couverture de livre' />
    </div>
  )
}

const CarouselItemComponent = ({ urlLite, displayTitle, year, onClick }) => {
  return (
    <div className={cssModule.itemContent} onClick={onClick}>
      <BookCover src={urlLite} />
      <span className={cssModule.itemTitle}>{displayTitle}</span>
      <span className={cssModule.itemSubtitle}>Collection {year}</span>
    </div>
  )
}

const CarouselComponent = ({ type, displayTitle, year, urlLite, name, onClick }) => {
  return type === 'title' ? (
    <span className={cssModule.carouselTitle}>{name}</span>
  ) : (
    <CarouselItemComponent onClick={onClick} displayTitle={displayTitle} year={year} urlLite={urlLite} />
  )
}

const PremiumCarousel = ({ items = [], onClick = _.noop }) => {
  const formattedItems = useMemo(() => formatBooksForCarousel({ groupedBooks: items, onClick }), [items, onClick])
  return (
    <div className={cssModule.carouselContainer}>
      <Carousel
        className={cssModule.carousel}
        items={formattedItems}
        width='100%'
        ComponentItem={CarouselComponent}
        scrollStep={2}
      />
    </div>
  )
}

export default PremiumCarousel
