// Third modules
import _ from 'lodash/fp.js'

export const isDirty = (fieldName, formState) => _.flow(_.get(`dirtyFields.${fieldName}`), _.isEqual(true))(formState)

export const isClean = _.negate(isDirty)

export const hasError = (fieldName, formState) => _.has(fieldName, formState.errors)

export const hasNoError = _.negate(hasError)

export const getHint = (fieldName, formState) => _.get(`errors.${fieldName}.message`, formState)

export const isDirtyAndWithoutError = _.overEvery([isDirty, hasNoError])

export const shouldDisabledSchoolsInput = user => {
  if (_.get('isReferent', user)) return true
  return _.get('isPremium', user)
}
