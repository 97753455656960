import _ from 'lodash/fp.js'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Wavy } from '@lls/lls-kit'
import { sortBooks } from '@lls/utils'
import { useLocation } from 'wouter'

import PremiumCarousel from 'connect/components/premiumAccess/carousel.jsx'
import { useFetch } from 'connect/hooks/useFetch.jsx'
import { getCurrentUser } from 'connect/slices/auth.jsx'
import { searchBooks } from 'connect/slices/books.jsx'
import { getLevels } from 'connect/slices/levels.jsx'
import { searchSchools } from 'connect/slices/schools.jsx'
import { searchSubjects } from 'connect/slices/subjects.jsx'
import { mergeUserForm } from 'connect/slices/userForm.jsx'

import Subjects from 'connect/components/premiumAccess/subjects.jsx'
import {
  StyledImgHeaderLeft,
  StyledImgHeaderRight,
  StyledLogo,
  getHeaderLeftImgByVariant,
  getHeaderRightImgByVariant
} from 'connect/components/screenContainer.jsx'
import { COOKIE_JWT } from 'connect/constants/index.jsx'
import { SCREEN_PREMIUM_ACCESS } from 'connect/constants/screen.jsx'
import { getGroupedBooks } from 'connect/utils/book.jsx'
import { getCookie } from 'connect/utils/cookie.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import { getConnectorPath } from 'connect/utils/school.jsx'
import cssModule from './premiumAccess.module.css'

const PremiumAccess = () => {
  const dispatch = useDispatch()
  const [, setLocation] = useLocation()

  const token = getCookie(COOKIE_JWT)
  const currentUser = useSelector(getCurrentUser)
  const levels = useSelector(getLevels)
  const books = useSelector(state => searchBooks(state, { ids: _.get('books', currentUser), isValid: true }))
  const subjects = useSelector(state => searchSubjects(state, { ids: _.flatMap('subjects', books) }))

  const groupedBooks = useMemo(() => {
    return _.flow(
      books => sortBooks(books, levels, subjects),
      sortedBooks => getGroupedBooks({ user: currentUser, books: sortedBooks, levels, subjects })
    )(books)
  }, [currentUser, books, levels, subjects])

  const userSchools = useSelector(state => searchSchools(state, { ids: _.get('schools', currentUser) }))
  const firstSchoolWithValidLicence = _.find(school => _.get('validLicenses.0', school), userSchools) // needed because we can have several schools as a teacher

  const subjectsToDisplay = _.reject(subject => _.includes(subject.id, _.get('subjects', currentUser)), subjects)

  useFetch({
    screen: SCREEN_PREMIUM_ACCESS,
    params: { token }
  })

  useEffect(() => {
    dispatch(mergeUserForm({ school: firstSchoolWithValidLicence }))
  }, [dispatch, firstSchoolWithValidLicence])

  const goTo = useCallback(
    book => {
      if (book) {
        dispatch(mergeUserForm({ book, level: _.get('levels.0', book) }))
      }
      setLocation(getPath(getConnectorPath(firstSchoolWithValidLicence)))
    },
    [firstSchoolWithValidLicence, dispatch, setLocation]
  )

  return (
    <div className={cssModule.container}>
      <div className={cssModule.topContent}>
        <StyledImgHeaderLeft src={getHeaderLeftImgByVariant('dark')} />
        <StyledImgHeaderRight src={getHeaderRightImgByVariant('dark')} />
        <StyledLogo src='/assets/img/logo-premium.svg' />
        <h2 className={cssModule.title}>
          Votre <Wavy waveColor='var(--premium-50-color)'>Lycée {_.get('name', firstSchoolWithValidLicence)}</Wavy> est
          abonné <br /> aux Manuels Numériques
        </h2>
      </div>
      <PremiumCarousel items={groupedBooks} onClick={goTo} school={firstSchoolWithValidLicence} />
      <div className={cssModule.ctaContainer}>
        <span className={cssModule.span}>Associez votre compte à l'abonnement Premium de votre établissement.</span>
        <div className={cssModule.buttonContainer}>
          <Button onClick={goTo} theme='yellow' maxSize>
            Finaliser ma connexion
          </Button>
        </div>
      </div>
      {!_.isEmpty(subjectsToDisplay) && <Subjects onClick={goTo} subjects={subjectsToDisplay} />}
    </div>
  )
}

export default PremiumAccess
