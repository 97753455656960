// Third modules
import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash/fp.js'

const initialState = {
  origin: null,
  redirect: null,
  bookId: null,
  pageId: null
}

export const relayStateSlice = createSlice({
  name: 'relayState',
  initialState,
  reducers: {
    mergeRelayState: (state, action) => {
      _.flow(_.get('payload'), _.pick(['origin', 'redirect', 'bookId', 'pageId']), obj => Object.assign(state, obj))(
        action
      )
    }
  }
})

export const getRelayState = _.get('relayState')

export const { mergeRelayState } = relayStateSlice.actions

export default relayStateSlice.reducer
