import _ from 'lodash/fp.js'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@lls/lls-kit'
import { getAllowedFormSchoolTypes } from '@lls/utils'
import { PremiumTitle } from 'connect/components/common.jsx'
import FormNotifications from 'connect/components/formNotifications.jsx'
import { Password, SchoolsSelector, StyledForm, StyledFormLogo, UserName } from 'connect/components/forms/index.jsx'
import SwitchConnection from 'connect/components/switchConnection.jsx'
import { ECOLEDIRECTE, ECOLE_DIRECTE_CONNECTOR_NAME } from 'connect/constants/ent.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { getUserForm } from 'connect/slices/userForm.jsx'
import { loginWithEnt } from 'connect/slices/users.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'

const EcoleDirectForm = () => {
  const dispatch = useDispatch()
  const schoolTypes = getAllowedFormSchoolTypes(useSelector(getSchoolTypes))

  const userForm = useSelector(getUserForm)

  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues: {
      departments: _.getOr(null, 'school.department', userForm),
      schools: _.getOr(null, 'school.id', userForm),
      username: '',
      password: ''
    }
  })

  const onSubmit = useCallback(
    data => {
      dispatch(
        loginWithEnt({
          ..._.pick(['username', 'password'], data),
          schoolId: data.schools,
          connector: ECOLE_DIRECTE_CONNECTOR_NAME
        })
      )
    },
    [dispatch]
  )

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormLogo src='/assets/img/ent/ecole-directe.svg' alt='ecole-directe-logo' />

      <PremiumTitle />

      <SchoolsSelector
        control={control}
        setValue={setValue}
        formState={formState}
        width='100%'
        schoolTypes={schoolTypes}
      />
      <UserName control={control} error={hasError('username', formState)} hint={getHint('username', formState)} />
      <Password control={control} error={hasError('password', formState)} hint={getHint('password', formState)} />

      <Button className='form-ecole-directe-button' type='submit' maxSize>
        Connexion avec ÉcoleDirecte
      </Button>

      <FormNotifications />

      <SwitchConnection connectionTo={ECOLEDIRECTE} />
    </StyledForm>
  )
}

export default EcoleDirectForm
