const config_dev = {
  env: 'development',
  api_url: 'https://api-dev.lelivrescolaire.fr',
  front_url: 'https://dev.lelivrescolaire.fr',
  premium_url: 'https://premium-dev.lelivrescolaire.fr',
  quiz_url: 'https://quiz-dev.lls.fr',
  at_internet_id: '621707',
  is_trial_enabled: true,
  epub_url: 'https://epub-dev.lelivrescolaire.fr',
  resources_url: 'https://ressources-dev.lelivrescolaire.fr'
}

const config_test = {
  env: 'test',
  api_url: 'https://api-dev.lelivrescolaire.fr',
  front_url: 'https://dev.lelivrescolaire.fr',
  premium_url: 'https://premium-dev.lelivrescolaire.fr',
  quiz_url: 'https://quiz-dev.lls.fr',
  at_internet_id: '621707',
  is_trial_enabled: true,
  epub_url: 'https://epub-dev.lelivrescolaire.fr',
  resources_url: 'https://ressources-dev.lelivrescolaire.fr'
}

const config_preprod = {
  env: 'preprod',
  api_url: 'https://api-preprod.lelivrescolaire.fr',
  front_url: 'https://preprod.lelivrescolaire.fr',
  premium_url: 'https://premium-preprod.lelivrescolaire.fr',
  quiz_url: 'https://quiz-dev.lls.fr',
  at_internet_id: '621707',
  is_trial_enabled: true,
  epub_url: 'https://epub-dev.lelivrescolaire.fr',
  resources_url: 'https://ressources-preprod.lelivrescolaire.fr'
}

const config_prod = {
  env: 'production',
  api_url: 'https://api.lelivrescolaire.fr',
  front_url: 'https://www.lelivrescolaire.fr',
  premium_url: 'https://premium.lelivrescolaire.fr',
  quiz_url: 'https://quiz.lls.fr',
  at_internet_id: '621708',
  is_trial_enabled: true,
  epub_url: 'https://epub.lelivrescolaire.fr',
  resources_url: 'https://ressources.lelivrescolaire.fr'
}

const config = env => {
  switch (true) {
    case env === 'preprod':
      return config_preprod
    case env === 'prod':
    case env === 'production':
      return config_prod
    case env === 'test':
      return config_test
    case env === 'local':
    case env === 'dev':
    case env === 'development':
      return config_dev
    default:
      return config_dev
  }
}
const conf_env = process.env.CONF_ENV || 'development'
// biome-ignore lint/suspicious/noConsoleLog:
console.log('Loaded conf: ', conf_env)
export default config(conf_env)
