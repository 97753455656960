import { sendEvent } from '@lls/utils'
import _ from 'lodash/fp'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'wouter'

import { ANALYTICS_EVENTS } from 'connect/constants/analytics.jsx'
import { COOKIE_FIRST_TIME_ACCESS, COOKIE_JWT } from 'connect/constants/index.jsx'
import { sendAnalytics } from 'connect/slices/analytics.jsx'
import { getCookie, remvoveCookie, setCookie } from 'connect/utils/cookie.jsx'

export default function Analytics() {
  const [location] = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    const firstTimeConnected = getCookie(COOKIE_FIRST_TIME_ACCESS)
    if (!firstTimeConnected) {
      dispatch(sendAnalytics({ event: ANALYTICS_EVENTS.LOGIN_STARTED }))
      setCookie(COOKIE_FIRST_TIME_ACCESS, Date.now())
    } else {
      if (getCookie(COOKIE_JWT)) {
        remvoveCookie(COOKIE_FIRST_TIME_ACCESS)
      }
    }
  }, [dispatch])

  useEffect(() => {
    _.noop(location)
    dispatch(sendAnalytics({ event: ANALYTICS_EVENTS.PAGE_VIEWED }))
    sendEvent({ name: 'page.display' })
  }, [location, dispatch])

  return null
}
