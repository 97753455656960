// Third modules
import { createAction } from '@reduxjs/toolkit'
import _ from 'lodash/fp.js'

export const setEntities = createAction('entities/set')

export const createEntitiesReducer =
  ({ entitiesName, adaptater }) =>
  builder => {
    builder.addCase(setEntities, (state, action) => {
      const entities = _.get(`payload.${entitiesName}`, action)
      if (_.isEmpty(entities)) return

      return adaptater.upsertMany(state, entities)
    })
  }
