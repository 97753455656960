import '@lls/lls-kit/clientImports'
import '@lls/superkit/clientImports'
// Third modules
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

// Our modules
import { App } from 'connect/app.jsx'
import { initStore } from 'connect/store.jsx'

const store = initStore({ preloadedState: window.__PRELOADED_STATE__ || {} })
window.__PRELOADED_STATE__ = ''
const container = document.getElementById('app')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
