import { userFragment } from 'connect/api/user.jsx'
// Our modules
import { normalizeData } from 'connect/schema/index.jsx'
import { gqlCached, gqlNoCache } from './graphql.jsx'

export const fetchScreenGlobal = () =>
  gqlCached({
    query: `query connectFetchGlobalScreen {
    viewer {
      schoolTypes {
        hits {
          id
          name
          order
        }
      }
    }
  }
`
  }).then(normalizeData)

export const fetchClassicAccessScreen = ({ uri }) =>
  gqlCached({
    query: `query connectFetchClassicAccessScreen ($uri: String!) {
      marketing {
        getRandomWord(bookUri: $uri) {
          id
          word
          bookUri
          page
          line
          number
        }
      }
      viewer {
        books(hasNewDesign: true, isValid: true, uris: [$uri] ) {
          hits {
            id
            valid
            displayTitle
            url
            uri
            hasNewDesign
            classicColor
            isClassicTextDark
          }
        }
      }
    }`,
    variables: {
      uri
    }
  }).then(normalizeData)

export const fetchTrialSubscriptionScreen = () =>
  gqlCached({
    query: `query {
      viewer {
        books(hasNewDesign: true, isValid: true) {
          hits {
            id
            valid
            hasNewDesign
            subjects {
              id
            }
          }
        }
        subjects {
          hits {
            id
            name
            code
            schoolTypes
          }
        }
        schoolTypes {
          hits {
            id
            name
            order
          }
        }
      }
    }`,
    variables: {}
  }).then(normalizeData)

export const fetchUpdateUserInfosScreen = () =>
  gqlCached({
    query: `query {
      viewer {
        subjects {
          hits {
            id
            name
            code
            schoolTypes
          }
        }
        levels {
          id
          name
          order
          isPreSchool
          isElementarySchool
          isMiddleSchool
          isHighSchool
          isGenHighSchool
          isProHighSchool
        }
        schoolTypes {
          hits {
            id
            name
            order
          }
        }
      }
    }`,
    variables: {}
  }).then(normalizeData)

export const fetchSchoolChoiceScreen = () =>
  gqlCached({
    query: `query {
      viewer {
        schoolTypes {
          hits {
            id
            name
            order
          }
        }
      }
    }`,
    variables: {}
  }).then(normalizeData)

export const fetchEntScreen = () =>
  gqlCached({
    query: `query {
      viewer {
        subjects {
          hits {
            id
            name
            code
          }
        }
        levels {
          id
          name
          order
        }
        books (hasNewDesign: true, isValid: true) {
          hits {
            id
            hasNewDesign
            displayTitle
            valid
            year
            levels {
              id
              name
              order
            }
            subjects {
              id
              name
            }
            textbooks {
              id
            }
            oldBook {
              id
            }
          }
        }
      }
    }`,
    variables: {}
  }).then(normalizeData)

export const fetchPremiumAccessScreen = ({ token }) =>
  gqlNoCache({
    query: `query connectFetchPremiumAccessScreen ($token: String!) {
      viewer(token: $token) {
        me {
          ${userFragment}
          books {
            id
            slug
            displayTitle
            year
            hasNewDesign
            valid
            urlLite
            oldBook {
              id
            }
            subjects{
              name
              id
              urlLite
            }
            levels{
              name
              id
            }
          }
        }
      }
    }
`,
    variables: {
      token
    }
  }).then(normalizeData)
