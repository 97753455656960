import _ from 'lodash/fp.js'
import cssModule from './subjects.module.css'

const Subject = ({ subject = {}, onClick }) => {
  return (
    <div className={cssModule.subjectContainer} onClick={onClick}>
      <span className={cssModule.title}>{_.get('name', subject)}</span>
      <img className={cssModule.img} src={_.get('urlLite', subject)} alt='' />
    </div>
  )
}

const Subjects = ({ subjects = [], onClick = _.noop }) => {
  return (
    <div className={cssModule.subjectsContainer}>
      <h3 className={cssModule.subjectsContainerTitle}>Découvrez-en plus dans ces matières :</h3>
      <div className={cssModule.subjectsContent}>
        {_.map(
          subject => (
            <Subject onClick={onClick} key={_.get('id', subject)} subject={subject} />
          ),
          subjects
        )}
      </div>
    </div>
  )
}

export default Subjects
