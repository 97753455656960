import dayjs from 'dayjs'
// Third modules
import _ from 'lodash/fp.js'
import Cookies from 'universal-cookie'

const getCookieOptions = ({ durationDays = 7 }) => {
  return {
    path: '/',
    expires: new Date(dayjs().add(durationDays, 'day').valueOf()),
    maxAge: durationDays * 24 * 60 * 60,
    sameSite: 'lax',
    secure: _.includes('localhost', window.location.host),
    ...(window.location.host.match(/localhost|127\.0\.0\.1/) ? {} : { domain: '.lelivrescolaire.fr' })
  }
}

const cookies = new Cookies()

export const setCookie = (name, value, durationDays) => cookies.set(name, value, getCookieOptions({ durationDays }))
export const getCookie = name => cookies.get(name)
export const remvoveCookie = name => cookies.remove(name)
