// Third modules

import { Modal } from '@lls/lls-kit'
import { StyledLink } from 'connect/components/common.jsx'
// Our modules
import ResetPasswordForm from 'connect/components/forms/resetPassword.jsx'

import cssModule from './forgottenPassword.module.css'

const ForgotPasswordModal = ({ close }) => {
  return (
    <Modal title='Récupérer son mot de passe' closeModal={close}>
      <div className={cssModule.modalContent}>
        <ResetPasswordForm />
        <StyledLink onClick={close} theme='tertiary' accent='floating' maxSize>
          Retour à la connexion
        </StyledLink>
      </div>
    </Modal>
  )
}

export default ForgotPasswordModal
