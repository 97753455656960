// Third modules
import _ from 'lodash/fp.js'

import { SCREEN_GLOBAL, SNACKBAR_NOTIFICATION } from 'connect/constants/index.jsx'
import { LOADED_STATUS, LOADING_STATUS } from 'connect/constants/loading.jsx'
// Our modules
import { addNotification, setLoadingScreen } from 'connect/slices/ui.jsx'

export const tryCatch =
  ({
    loadingName = SCREEN_GLOBAL,
    effect = _.noop,
    silent = false,
    errorName = 'effectError',
    errorMessage,
    notificationType = SNACKBAR_NOTIFICATION
  }) =>
  async (action, listenerApi) => {
    try {
      if (!navigator.onLine) {
        throw new Error('Pas de réseau disponible. Veuillez vérifier votre connexion Internet.')
      }

      !silent &&
        listenerApi.dispatch(setLoadingScreen({ name: loadingName, params: action.payload, status: LOADING_STATUS }))
      return await effect(action, listenerApi)
    } catch (e) {
      /* eslint-disable no-console */
      console.error('effect error : ', e)

      const graphqlErrors = _.flow(_.get('response.data.errors'), _.map('message'), _.join(' | '))(e)
      !silent &&
        listenerApi.dispatch(
          addNotification({
            name: errorName,
            title: e.title ?? null,
            message: errorMessage || graphqlErrors || _.getOr(_.get('message', e), 'response.data', e),
            severity: 'error',
            type: notificationType
          })
        )
    } finally {
      !silent &&
        listenerApi.dispatch(setLoadingScreen({ name: loadingName, params: action.payload, status: LOADED_STATUS }))
    }
  }
