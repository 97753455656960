// Third modules
import { configureStore } from '@reduxjs/toolkit'

import config from 'connect/config/index.js'
import listenerMiddleware from 'connect/effects/index.jsx'
// Our modules
import analytics from 'connect/slices/analytics.jsx'
import auth from 'connect/slices/auth.jsx'
import books from 'connect/slices/books.jsx'
import levels from 'connect/slices/levels.jsx'
import relayState from 'connect/slices/relayState.jsx'
import schoolTypes from 'connect/slices/schoolTypes.jsx'
import schools from 'connect/slices/schools.jsx'
import subjects from 'connect/slices/subjects.jsx'
import ui from 'connect/slices/ui.jsx'
import userForm from 'connect/slices/userForm.jsx'
import users from 'connect/slices/users.jsx'
import words from 'connect/slices/words.jsx'

const logger =
  ({ getState }) =>
  next =>
  action => {
    console.groupCollapsed(`action ${action.type}`)
    // biome-ignore lint/suspicious/noConsoleLog:
    console.log('prev state:', getState())
    // biome-ignore lint/suspicious/noConsoleLog:
    console.log('action:', action)
    next(action)
    // biome-ignore lint/suspicious/noConsoleLog:
    console.log('next state:', getState())
    console.groupEnd()
  }

export const initStore = ({ preloadedState = {} } = {}) => {
  const store = configureStore({
    reducer: { ui, users, auth, schools, levels, subjects, books, words, schoolTypes, relayState, userForm, analytics },
    middleware: getDefaultMiddleware => {
      return config.env === 'development'
        ? getDefaultMiddleware({
            thunk: false,
            immutableCheck: true,
            serializableCheck: true
          })
            .concat(logger)
            .concat(listenerMiddleware.middleware)
        : [listenerMiddleware.middleware]
    },
    preloadedState
  })
  return store
}
