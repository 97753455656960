import { CheckBox, SelectField, TextField } from '@lls/lls-kit'
import _ from 'lodash/fp.js'
// Third modules
import { useEffect, useState } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { displayDepartment, displaySchool, groupSchoolByCity, makeComponent } from '@lls/utils'

import { searchSchools } from 'connect/api/school.tsx'
import departments from 'connect/constants/department.jsx'
// Our modules
import useDidUpdate from 'connect/hooks/useDidUpdate.jsx'
import { setEntities } from 'connect/slices/entities.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'
import cssModule from './index.module.css'

export const StyledForm = makeComponent('form', cssModule.form)

export const StyledFormLogo = makeComponent('img', cssModule.formLogo)

export const Password = ({
  control,
  name = 'password',
  label = 'Mot de passe',
  error = false,
  hint,
  width = '100%'
}) => (
  <Controller
    rules={{ required: 'Mot de passe obligatoire' }}
    name={name}
    control={control}
    render={({ field }) => (
      <TextField
        label={label}
        state={error ? 'error' : undefined}
        type='password'
        hint={hint}
        width={width}
        {...field}
      />
    )}
  />
)

export const ContentNewsletter = ({ control, ...props }) => (
  <Controller
    control={control}
    name='hasNoContentNewsletter'
    render={({ field }) => {
      return (
        <CheckBox
          size='small'
          color='primary'
          label={
            <span className={cssModule.labelCheckbox}>
              Je ne souhaite pas recevoir la newsletter de partage de ressources et nouveautés.
            </span>
          }
          {...props}
          {...field}
        />
      )
    }}
  />
)

export const Gender = ({ control, name = 'gender', isStudent, label = 'Civilité *', error = false, ...props }) => (
  <Controller
    rules={isStudent ? {} : { required: 'Civilité obligatoire' }}
    name={name}
    control={control}
    render={({ field }) => (
      <SelectField
        options={[
          {
            value: 'female',
            label: 'Madame'
          },
          {
            value: 'male',
            label: 'Monsieur'
          },
          {
            value: 'other',
            label: 'Ne souhaite pas répondre'
          }
        ]}
        label={label}
        state={error ? 'error' : undefined}
        {...props}
        {...field}
      />
    )}
  />
)

export const UserName = ({
  control,
  name = 'username',
  label = "Adresse email ou nom d'utilisateur",
  error = false,
  hint,
  width = '100%'
}) => (
  <Controller
    rules={{ required: "Adresse email ou nom d'utilisateur obligatoire" }}
    name={name}
    control={control}
    render={({ field }) => (
      <TextField label={label} state={error ? 'error' : undefined} hint={hint} width={width} {...field} />
    )}
  />
)

export const Email = ({
  control,
  name = 'email',
  label = 'Mon adresse e-mail',
  error = false,
  width = '100%',
  hint
}) => (
  <Controller
    rules={{
      required: 'Email obligatoire',
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Email incorrect'
      }
    }}
    name={name}
    control={control}
    render={({ field }) => (
      <TextField label={label} state={error ? 'error' : undefined} hint={hint} width={width} {...field} />
    )}
  />
)

export const Profiles = ({ control, name = 'profiles', label = 'Profil *', error = false, ...props }) => (
  <Controller
    rules={{ required: 'Profil obligatoire' }}
    name={name}
    control={control}
    render={({ field }) => (
      <SelectField
        options={[
          {
            value: 'teacher',
            label: 'Professeur'
          },
          {
            value: 'student',
            label: 'Élève'
          }
        ]}
        label={label}
        state={error ? 'error' : undefined}
        {...props}
        {...field}
      />
    )}
  />
)

export const FirstName = ({ control, name = 'firstname', label = 'Prénom *', error = false, ...props }) => (
  <Controller
    rules={{ required: 'Prénom obligatoire' }}
    name={name}
    control={control}
    render={({ field }) => <TextField label={label} state={error ? 'error' : undefined} {...props} {...field} />}
  />
)

export const LastName = ({ control, name = 'lastname', label = 'Nom *', error = false, ...props }) => (
  <Controller
    rules={{ required: 'Nom obligatoire' }}
    name={name}
    control={control}
    render={({ field }) => <TextField label={label} state={error ? 'error' : undefined} {...props} {...field} />}
  />
)

const Departments = ({
  control,
  label = 'Choisissez un département *',
  filterFn = _.identity,
  multiple,
  error,
  ...props
}) => (
  <Controller
    rules={{ required: 'Obligatoire pour sélectionner un établissement' }}
    name='departments'
    control={control}
    render={({ field }) => (
      <SelectField
        searchable
        multiple={multiple}
        label={label}
        listMaxHeight='200px'
        state={error ? 'error' : undefined}
        {...props}
        options={_.flow(
          _.filter(filterFn),
          _.sortBy('zipcode'),
          _.map(department => ({
            value: _.get('zipcode', department),
            label: displayDepartment(department)
          }))
        )(departments)}
        {...field}
      />
    )}
  />
)

export const Schools = ({ label = 'Choisissez un établissement *', schools, control, multiple, error, ...props }) => (
  <Controller
    rules={{ required: 'Établissement scolaire obligatoire' }}
    name='schools'
    control={control}
    render={({ field }) => (
      <SelectField
        searchable
        multiple={multiple}
        label={label}
        listMaxHeight='200px'
        state={error ? 'error' : undefined}
        {...props}
        options={_.flow(
          groupSchoolByCity,
          _.toPairs,
          _.map(([city, sortedSchools]) => ({
            label: city,
            options: _.map(
              school => ({
                value: _.get('id', school),
                label: displaySchool(school),
                group: city
              }),
              sortedSchools
            )
          }))
        )(schools)}
        {...field}
      />
    )}
  />
)

export const SchoolsSelector = ({
  control,
  departmentFilterFn,
  onChange = _.noop,
  labels: { department: departmentLabel, schools: schoolsLabel } = {},
  setValue,
  multiple = false,
  formState,
  defaultSchools = [],
  disabled = false,
  width,
  schoolTypes
}) => {
  const watchDepartment = useWatch({
    control,
    name: 'departments'
  })
  const watchSchoolTypes = useWatch({
    control,
    name: 'schoolTypes'
  })

  const [schoolsOptions, setSchoolsOptions] = useState(defaultSchools)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchSchools = async () => {
      const { entities } = await searchSchools({
        departments: _.castArray(watchDepartment),
        schoolTypes: _.castArray(watchSchoolTypes)
      })
      const schools = _.values(entities.schools)
      dispatch(setEntities(entities))
      setSchoolsOptions(schools)
      onChange(schools)
    }

    !_.isEmpty(watchDepartment) && !_.isEmpty(watchSchoolTypes) && fetchSchools()
  }, [watchDepartment, watchSchoolTypes, onChange, dispatch])

  useDidUpdate(() => {
    if (!multiple) setValue('schools', null)
  }, [watchDepartment, watchSchoolTypes, setValue, multiple])

  return (
    <>
      <Departments
        control={control}
        label={departmentLabel}
        filterFn={departmentFilterFn}
        multiple={multiple}
        error={hasError('departments', formState)}
        hint={getHint('departments', formState)}
        disabled={disabled}
        width={width}
      />

      <SchoolTypes
        control={control}
        multiple
        schoolTypes={schoolTypes}
        error={hasError('schoolTypes', formState)}
        hint={getHint('schoolTypes', formState)}
        disabled={disabled}
        width={width}
      />

      <Schools
        schools={schoolsOptions}
        control={control}
        label={schoolsLabel}
        multiple={multiple}
        error={hasError('schools', formState)}
        hint={getHint('schools', formState)}
        disabled={disabled}
        width={width}
      />
    </>
  )
}

export const Levels = ({ control, label: iLabel, levels, error, required = true, ...props }) => {
  const label = iLabel || `Niveau${_.get('multiple', props) ? '(x)' : ''}${required ? ' *' : ''}`
  return (
    <Controller
      rules={required ? { required: 'Niveau obligatoire' } : {}}
      name='levels'
      control={control}
      render={({ field }) => (
        <SelectField
          label={label}
          listMaxHeight='200px'
          state={error ? 'error' : undefined}
          options={_.map(
            level => ({
              value: _.get('id', level),
              label: _.get('name', level)
            }),
            levels
          )}
          {...props}
          {...field}
        />
      )}
    />
  )
}

export const Subjects = ({ control, label = 'Choisissez votre niveau/matière *', subjects, error, ...props }) => (
  <Controller
    rules={{ required: 'Niveau/Matière obligatoire' }}
    name='subjects'
    control={control}
    render={({ field }) => (
      <SelectField
        label={label}
        listMaxHeight='200px'
        state={error ? 'error' : undefined}
        options={_.map(
          subject => ({
            value: _.get('id', subject),
            label: _.get('name', subject)
          }),
          subjects
        )}
        {...props}
        {...field}
      />
    )}
  />
)

export const SchoolTypes = ({
  control,
  name = 'schoolTypes',
  rules,
  label = "Choisissez votre niveau d'enseignement *",
  schoolTypes,
  error,
  ...props
}) => (
  <Controller
    name={name}
    rules={rules}
    control={control}
    render={({ field }) => (
      <SelectField
        label={label}
        listMaxHeight='200px'
        state={error ? 'error' : undefined}
        options={_.map(
          schoolType => ({
            value: _.get('name', schoolType),
            label: _.get('name', schoolType)
          }),
          schoolTypes
        )}
        {...props}
        {...field}
      />
    )}
  />
)

export const Functions = ({
  control,
  label = 'Fonction(s) *',
  functions = [
    'Professeur',
    'Professeur coordonnateur',
    "Directeur d'établissement",
    'Directeur adjoint',
    'Référent numérique',
    'Référent GAR',
    'Gestionnaire',
    'Gestionnaire Adjoint',
    'Autre'
  ],

  multiple,
  error,
  ...props
}) => (
  <Controller
    name='functions'
    rules={{ required: 'Fonction obligatoire' }}
    control={control}
    render={({ field }) => (
      <SelectField
        label={label}
        listMaxHeight='200px'
        state={error ? 'error' : undefined}
        multiple={multiple}
        options={_.map(
          func => ({
            value: func,
            label: func
          }),
          functions
        )}
        {...props}
        {...field}
      />
    )}
  />
)

export const Books = ({ control, label = 'Manuel(s)', books, error, ...props }) => (
  <Controller
    rules={{ required: 'Manuel obligatoire' }}
    name='books'
    control={control}
    render={({ field }) => (
      <SelectField
        label={label}
        listMaxHeight='200px'
        state={error ? 'error' : undefined}
        options={_.map(
          item => ({
            value: _.get('id', item),
            label: _.get('displayTitle', item)
          }),
          books
        )}
        {...props}
        {...field}
      />
    )}
  />
)

export const Word = ({ control, name = 'word', label = "De quel mot s'agit-il ?", error = false, ...props }) => (
  <Controller
    rules={{ required: 'Mot obligatoire' }}
    name={name}
    control={control}
    render={({ field }) => <TextField label={label} state={error ? 'error' : undefined} {...props} {...field} />}
  />
)
