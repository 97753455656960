export const ANALYTICS_EVENTS = {
  ACCOUNT_CREATED: 'account_created',
  ACCOUNT_ACTIVATED: 'account_activated',
  USER_LOGGED_IN: 'user_logged_in',
  PAGE_VIEWED: 'page_viewed',
  LINK_CLICKED: 'link_clicked',
  ACTIVATION_FAILED: 'activation_failed',
  LOGIN_STARTED: 'login_started',
  PROFILE_UPDATED: 'profile_updated',
  ENT_CONNECTION_STARTED: 'ent_connection_started'
}
