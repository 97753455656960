import { createSearchSelector, filterEntitiesSimpleFieldByValues } from '@lls/utils'
// Third modules
import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash/fp.js'

import { SCREEN_GLOBAL } from 'connect/constants/index.jsx'
import { LOADED_STATUS, LOADING_STATUS } from 'connect/constants/loading.jsx'
// Our modules
import { getScreenLoadingEventName } from 'connect/utils/loading.jsx'

const initialState = {
  openModal: {
    name: null,
    data: null
  },
  loading: {},
  notifications: []
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.openModal.name = _.get('payload.name', action)
      state.openModal.data = _.get('payload.data', action)
    },
    closeModal: state => {
      state.openModal = {}
    },
    setLoadingScreen: (state, action) => {
      const screenName = _.get('payload.name', action)
      const screenParams = _.get('payload.params', action)
      const screenStatus = _.get('payload.status', action)

      const screenEvent =
        screenName === SCREEN_GLOBAL ? screenName : getScreenLoadingEventName(screenName, screenParams)

      state.loading[screenEvent] = screenStatus
    },
    addNotification: (state, action) => {
      const notification = _.get('payload', action)
      state.notifications.push(notification)
    },
    removeNotifications: (state, action) => {
      const name = _.get('payload.name', action)
      const type = _.get('payload.type', action)
      const rejectParams = _.pickBy(_.negate(_.isNil))({ name, type })

      state.notifications = _.reject(rejectParams, state.notifications)
    }
  }
})

export const getLoadingStatus = (state, screen, params) => {
  const loadingState = _.get('ui.loading', state)

  return _.flow(
    screen => getScreenLoadingEventName(screen, params),
    screenLoadingEventName => _.get(screenLoadingEventName, loadingState)
  )(screen)
}

export const getIsLoading = _.flow(getLoadingStatus, _.isEqual(LOADING_STATUS))
export const getIsLoaded = _.flow(getLoadingStatus, _.isEqual(LOADED_STATUS))

export const getNotifications = _.get('ui.notifications')

export const makeSearchNotifications = () =>
  createSearchSelector(
    getNotifications,
    (_state, params) => params,
    (notifications, { types }) => _.flow(filterEntitiesSimpleFieldByValues('type', types))(notifications)
  )

export const searchNotifications = makeSearchNotifications()

export const { openModal, closeModal, setLoadingScreen, addNotification, removeNotifications } = uiSlice.actions

export default uiSlice.reducer
