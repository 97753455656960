import _ from 'lodash/fp.js'
// Third modules
import { useCallback, useState } from 'react'

import { ConnectionButton } from 'connect/components/button/index.jsx'
import { ConnectText, StyledLink } from 'connect/components/common.jsx'
import { ANALYTICS_EVENTS } from 'connect/constants/analytics.jsx'
// Our modules
import { CONNECTIONS_CHOICES } from 'connect/constants/ent.jsx'
import { sendAnalytics } from 'connect/slices/analytics.jsx'
import { useDispatch } from 'react-redux'
import cssModule from './switchConnection.module.css'

const map = _.map.convert({ cap: false })

const SwitchConnection = ({ connectionTo }) => {
  const [isDisplayed, setIsDisplayed] = useState(false)
  const choices = _.reject(choice => choice === connectionTo, CONNECTIONS_CHOICES)
  const dispatch = useDispatch()
  const displayConnection = useCallback(() => {
    !isDisplayed && dispatch(sendAnalytics({ event: ANALYTICS_EVENTS.LINK_CLICKED }))
    setIsDisplayed(true)
  }, [dispatch, isDisplayed])

  return (
    <div className={cssModule.switchConnectionContainer}>
      {isDisplayed ? (
        <div className={cssModule.content}>
          <ConnectText />
          <div className={cssModule.choices}>
            {map(
              (choice, i) => (
                <ConnectionButton key={i} choice={choice} />
              ),
              choices
            )}
          </div>
        </div>
      ) : (
        <StyledLink onClick={displayConnection}>Afficher les autres moyens de connexion</StyledLink>
      )}
    </div>
  )
}

export default SwitchConnection
