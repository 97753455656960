import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { createEntitiesReducer } from './entities.jsx'

const usersAdapter = createEntityAdapter()

const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  extraReducers: createEntitiesReducer({ entitiesName: 'users', adaptater: usersAdapter })
})

export default usersSlice.reducer

export const { selectAll: getUsers } = usersAdapter.getSelectors(_.get('users'))

export const resetPassword = createAction('users/resetPassword')
export const loginWithUsernameAndPassword = createAction('users/loginWithUsernameAndPassword')
export const loginWithToken = createAction('users/loginWithToken')
export const updateUserInfos = createAction('users/updateUserInfos')
export const loginWithEnt = createAction('users/loginWithEnt')
export const checkToken = createAction('users/checkToken')
export const fetchAllowedBooks = createAction('users/fetchAllowedBooks')
export const generateTrialUser = createAction('users/generateTrialUser')
