import cssModule from './legalNotice.module.css'

const LegalNotice = () => {
  const CGUUrl = 'https://www.lelivrescolaire.fr/informations/conditions-d-utilisation'
  const charterUrl = 'https://www.lelivrescolaire.fr/informations/donnees-personnelles'

  return (
    <div className={cssModule.legalNoticeContainer}>
      En cliquant sur connexion, vous acceptez que Lelivrescolaire.fr accède aux informations suivantes&nbsp;: nom,
      prénom, établissement, email, genre.
      <br />
      Ces informations ne sont pas transmises à des tiers.
      <br />
      Vous acceptez également les{' '}
      <a href={CGUUrl} target='_blank' rel='noreferrer'>
        CGU
      </a>{' '}
      et la{' '}
      <a href={charterUrl} target='_blank' rel='noreferrer'>
        charte des données personnelles
      </a>
      .
    </div>
  )
}

export default LegalNotice
