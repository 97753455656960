import { ORIGINS } from 'connect/constants/index.jsx'
import { ROUTE } from 'connect/constants/routes.jsx'
import _ from 'lodash/fp.js'

export const getActivationStepFromPath = path => {
  switch (path) {
    case ROUTE.HOME:
    case ROUTE.SCHOOL_CHOICES:
      return 'main'
    case ROUTE.ENT:
    case ROUTE.ECOLE_DIRECT:
    case ROUTE.ATRIUM:
    case ROUTE.OTHER_ENT:
      return 'ent'
    case ROUTE.UPDATE:
      return 'update'
    case ROUTE.PREMIUM_LOGIN:
      return 'account'
    case ROUTE.CLASSIC_ACCESS:
      return 'classic'
    default:
      return 'main'
  }
}

export const getInfoFromQueryParams = ({ origin, bookId, pageId, at_campaign, at_medium, path }) => {
  let pageSource
  switch (true) {
    case origin === ORIGINS.APP:
      pageSource = 'app'
      break
    case path === ROUTE.SCHOOL_CHOICES && !!at_campaign:
      pageSource = at_campaign
      break
    case !!at_medium:
      pageSource = `${at_medium}${at_campaign ? `|${at_campaign}` : ''}`
      break
    default:
      pageSource = 'navbar'
  }
  return _.omitBy(_.isNil, { pageSource, pageId, bookId })
}

const blacklist = ['/settoken']

export const isUrlBlackListed = url => _.some(blacklistUrl => _.startsWith(blacklistUrl, _.toLower(url)), blacklist)
