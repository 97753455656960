import _ from 'lodash/fp.js'
// Third modules
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchClassicAccessScreen,
  fetchEntScreen,
  fetchPremiumAccessScreen,
  fetchSchoolChoiceScreen,
  fetchScreenGlobal,
  fetchTrialSubscriptionScreen,
  fetchUpdateUserInfosScreen
} from 'connect/api/screen.jsx'
import { SCREEN_GLOBAL, SNACKBAR_NOTIFICATION } from 'connect/constants/index.jsx'
import { LOADED_STATUS, LOADING_STATUS } from 'connect/constants/loading.jsx'
// Our modules
import {
  SCREEN_CLASSIC_ACCESS,
  SCREEN_ENT,
  SCREEN_PREMIUM_ACCESS,
  SCREEN_SCHOOL_CHOICE,
  SCREEN_TRIAL_SUBSCRIPTION,
  SCREEN_UPDATE_USER_INFOS
} from 'connect/constants/screen.jsx'
import { setEntities } from 'connect/slices/entities.jsx'
import { addNotification, getLoadingStatus, setLoadingScreen } from 'connect/slices/ui.jsx'
import { getScreenLoadingEventName } from 'connect/utils/loading.jsx'

const fetchData = ({ screen, params = {} }) => {
  switch (screen) {
    case SCREEN_GLOBAL:
      return fetchScreenGlobal()
    case SCREEN_TRIAL_SUBSCRIPTION:
      return fetchTrialSubscriptionScreen(params)
    case SCREEN_UPDATE_USER_INFOS:
      return fetchUpdateUserInfosScreen(params)
    case SCREEN_ENT:
      return fetchEntScreen(params)
    case SCREEN_PREMIUM_ACCESS:
      return fetchPremiumAccessScreen(params)
    case SCREEN_CLASSIC_ACCESS:
      return fetchClassicAccessScreen(params)
    case SCREEN_SCHOOL_CHOICE:
      return fetchSchoolChoiceScreen(params)
  }
}
export const fetch = async ({ dispatch, screen, params = {}, loading }) => {
  const screenEvent = getScreenLoadingEventName(screen, params)
  if (!_.isNil(loading)) {
    return
  }

  dispatch(setLoadingScreen({ name: screenEvent, status: LOADING_STATUS }))
  const result = await fetchData({ screen, params })
  const entities = _.get('entities', result)

  dispatch(setEntities(entities))
  dispatch(setLoadingScreen({ name: screenEvent, status: LOADED_STATUS }))

  return result
}

export const useFetch = ({ screen, params = {}, shouldFetch = true }) => {
  const loading = useSelector(state => getLoadingStatus(state, screen, params))
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchEffect() {
      if (_.isNil(loading) && shouldFetch) {
        try {
          await fetch({ dispatch, screen, params })
        } catch (e) {
          /* eslint-disable no-console */
          console.error('fetch error:', e)
          dispatch(
            addNotification({
              name: 'fetch_error',
              message: 'Erreur lors de la récupération des données',
              severity: 'error',
              type: SNACKBAR_NOTIFICATION
            })
          )
        }
      }
    }
    fetchEffect()
  }, [dispatch, screen, params, shouldFetch, loading])
}
