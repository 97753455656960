// Third modules
import _ from 'lodash/fp.js'

import { searchSchools } from 'connect/api/school.tsx'
import { SET_CHOOL_BY_UAI } from 'connect/constants/index.jsx'
import { setEntities } from 'connect/slices/entities.jsx'
// Our modules
import { mergeUserForm, setSchoolByUai } from 'connect/slices/userForm.jsx'

import { tryCatch } from './utils.jsx'

export const setSchoolByUaiEffect = async (action, listenerApi) => {
  const uai = _.get('payload', action)
  const { entities } = await searchSchools({ rnes: [uai] })

  listenerApi.dispatch(setEntities(entities))
  const school = _.flow(_.values, _.first)(entities.schools)
  listenerApi.dispatch(mergeUserForm({ school }))
}

export const setSchoolByUaiEffectCreator = {
  actionCreator: setSchoolByUai,
  effect: tryCatch({ loadingName: SET_CHOOL_BY_UAI, effect: setSchoolByUaiEffect })
}
