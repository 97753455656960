import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { createEntitiesReducer } from './entities.jsx'

const schoolTypesAdapter = createEntityAdapter({
  sortComparer: (schoolType1 = {}, schoolType2 = {}) => {
    if (_.isNil(schoolType1.order) || _.isNil(schoolType2.order)) return

    if (schoolType1.order < schoolType2.order) return -1
    if (schoolType1.order === schoolType2.order) return 0
    if (schoolType1.order > schoolType2.order) return 1
  }
})

const schoolTypesSlice = createSlice({
  name: 'schoolTypes',
  initialState: schoolTypesAdapter.getInitialState(),
  extraReducers: createEntitiesReducer({ entitiesName: 'schoolTypes', adaptater: schoolTypesAdapter })
})

export const { selectAll: getSchoolTypes } = schoolTypesAdapter.getSelectors(_.get('schoolTypes'))

export default schoolTypesSlice.reducer
