import { Button } from '@lls/lls-kit'
import _ from 'lodash/fp.js'
import { useLocation } from 'wouter'

import { makeComponent } from '@lls/utils'
import { cx } from '@lls/utils'
import ConnectButton from 'connect/components/connectButton.jsx'
import { FloatingConnectToFree } from 'connect/components/floatingTexts.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import cssModule from './screenContainer.module.css'

export const StyledImgHeaderLeft = makeComponent('img', cssModule.imgHeaderLeft)

export const StyledImgHeaderRight = makeComponent('img', cssModule.imgHeaderRight)

export const StyledLogo = makeComponent('img', cssModule.logo)

const getLogoByVariant = (variant, premiumLogo) => {
  switch (true) {
    case variant === 'light':
      return '/assets/img/logo.webp'
    case variant === 'mixed':
    case variant === 'dark' && !premiumLogo:
      return '/assets/img/logo-white.svg'
    case variant === 'dark' && premiumLogo:
      return '/assets/img/logo-premium.svg'
  }
}

export const getHeaderLeftImgByVariant = variant => {
  switch (variant) {
    case 'light':
      return '/assets/img/header-left-light.webp'
    case 'mixed':
    case 'dark':
      return '/assets/img/header-left-dark.webp'
  }
}

export const getHeaderRightImgByVariant = variant => {
  switch (variant) {
    case 'light':
      return '/assets/img/header-right-light.webp'
    case 'mixed':
    case 'dark':
      return '/assets/img/header-right-dark.webp'
  }
}

export const AnimatedPicture = ({ styleProps, styleImg, imageName = '', isMobileDisplayed }) => {
  return (
    <div className={cssModule.imgContainer} style={styleProps} data-display-mobile={isMobileDisplayed}>
      <img style={styleImg} src={`/assets/img/update-user-infos/${imageName}.webp`} alt='' />
    </div>
  )
}

const IMAGES_PROPS = [
  {
    imageName: 'autumn-calendar',
    isMobileDisplayed: '1',
    styleProps: { left: '33%', top: '900px' },
    styleImg: { width: '216px', transform: 'rotate(15deg)' }
  },
  {
    imageName: 'bagpack',
    isMobileDisplayed: '1',
    styleProps: { left: '74%', top: '763px' },
    styleImg: { width: '120px' }
  },
  {
    imageName: 'pencilcase',
    isMobileDisplayed: '0',
    styleProps: { left: '88%', top: '26px' },
    styleImg: { width: '120px' }
  },
  {
    imageName: 'winter-calendar',
    isMobileDisplayed: '0',
    styleProps: { left: '60%', top: '162px' },
    styleImg: { width: '300px', transform: 'rotate(15deg)' }
  },
  {
    imageName: 'sun',
    isMobileDisplayed: '1',
    styleProps: { left: '94%', top: '422px' },
    styleImg: { width: '216px', transform: 'rotate(-30deg)' }
  },
  {
    imageName: 'quote',
    isMobileDisplayed: '0',
    styleProps: { left: '10%', top: '147px' },
    styleImg: { width: '200px', transform: 'rotate(-30deg)' }
  },
  {
    imageName: 'book',
    isMobileDisplayed: '0',
    styleProps: { left: '1%', top: '500px' },
    styleImg: { width: '140px', transform: 'rotate(-30deg)' }
  },
  {
    imageName: 'coffe',
    isMobileDisplayed: '1',
    styleProps: { left: '13%', top: '736px' },
    styleImg: { width: '120px', transform: 'rotate(-30deg)' }
  },
  { imageName: 'pen', isMobileDisplayed: '0', styleProps: { left: '55%', top: '650px' }, styleImg: { width: '74px' } }
]
export const AnimatedPictures = () => (
  <div className={cssModule.imagesContainer}>
    {_.map(
      props => (
        <AnimatedPicture key={props.imageName} {...props} />
      ),
      IMAGES_PROPS
    )}
  </div>
)

const ScreenContainer = ({
  premiumLogo = false,
  variant = 'mixed',
  children,
  backButton = false,
  onBackClick = _.noop,
  backPath = 'home',
  connectButton = false,
  direction = 'column',
  contentWidth = '320px',
  className,
  widthAnimatedPictures = false
}) => {
  const [, setLocation] = useLocation()
  return (
    <div className={cx(className, cssModule.container)} data-variant={variant}>
      <div className={cssModule.topBlock}>
        <img className={cssModule.logo} src={getLogoByVariant(variant, premiumLogo)} alt='logo premium' />
      </div>
      <div className={cssModule.bottomBlock} />
      <div className={cssModule.wrapperContent}>
        {widthAnimatedPictures && <AnimatedPictures />}
        <div
          className={cssModule.contentBlock}
          style={{
            '--connect-direction': direction,
            '--connect-contentwidth': contentWidth,
            '--connect-marginTop': '0px'
          }}
        >
          {backButton && (
            <Button
              className={cssModule.backButton}
              theme='gray'
              accent='floating'
              icon='arrowleft'
              onClick={() => {
                onBackClick()
                setLocation(getPath(backPath))
              }}
            >
              Retour
            </Button>
          )}

          {children}
        </div>

        {connectButton && (
          <div
            className={cssModule.contentBlock}
            style={{
              '--connect-direction': direction,
              '--connect-contentwidth': contentWidth,
              '--connect-marginTop': '30px'
            }}
          >
            <FloatingConnectToFree position='left' />
            <ConnectButton onClick={() => setLocation(getPath('free_access'))} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ScreenContainer
