import _ from 'lodash/fp.js'

import { validateWordApi } from 'connect/api/marketing.jsx'
import { COOKIE_JWT_CLASSICS, SNACKBAR_NOTIFICATION } from 'connect/constants/index.jsx'
import { getToken } from 'connect/slices/auth.jsx'
import { addNotification } from 'connect/slices/ui.jsx'
import { validateWord as validateWordAction } from 'connect/slices/words.jsx'
import { getCookie, setCookie } from 'connect/utils/cookie.jsx'
import { tryCatch } from './utils.jsx'

export const validateWordEffect = async ({ payload }, listenerApi) => {
  const { word, bookUri, redirect } = payload
  const existingToken = getCookie(COOKIE_JWT_CLASSICS)
  const token = getToken(listenerApi.getState())

  const newToken = await validateWordApi({ word, bookUri, classicsToken: existingToken, token }).then(
    _.get('marketing.validateWord')
  )

  listenerApi.dispatch(
    addNotification({
      name: 'word-success',
      message: 'Mot valide',
      severity: 'success',
      type: SNACKBAR_NOTIFICATION
    })
  )

  setCookie(COOKIE_JWT_CLASSICS, newToken, 60)
  window.open(redirect, '_self')
}

export const validateWordEffectCreator = {
  actionCreator: validateWordAction,
  effect: tryCatch({ effect: validateWordEffect, errorMessage: 'Erreur dans le mot renseigné' })
}
