import _ from 'lodash/fp.js'

export const getGroupedBooks = ({ user, books, levels, subjects }) => {
  const isTeacher = _.get('isTeacher', user)
  const userSubjects = _.get('subjects', user)
  return _.flow(
    _.map(book => {
      const booksSubjectId = _.get('subjects.0', book)
      const booksLevelId = _.get('levels.0', book)
      return {
        ...book,
        subjects: [_.find({ id: booksSubjectId }, subjects)],
        levels: [_.find({ id: booksLevelId }, levels)]
      }
    }),
    books => _.orderBy(book => _.includes(_.get('subjects.0.id', book), userSubjects), 'desc', books),
    _.groupBy(isTeacher ? 'subjects[0].name' : 'levels[0].name'),
    _.toPairs,
    _.map(([name, books]) => {
      return { name, books }
    })
  )(books)
}

export const formatBooksForCarousel = ({ groupedBooks = [], onClick = _.noop }) => {
  return _.flatMap(item => {
    const title = { type: 'title', name: _.get('name', item) }
    const items = {
      type: 'group',
      children: _.flow(_.get('books'), books =>
        _.map(book => ({ ...book, type: 'item', onClick: () => onClick(book) }), books)
      )(item)
    }
    return [title, items]
  }, groupedBooks)
}
