import { createSearchSelector, filterEntitiesSimpleFieldByValues } from '@lls/utils'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { createEntitiesReducer } from './entities.jsx'

const levelsAdapter = createEntityAdapter({
  sortComparer: (level1 = {}, level2 = {}) => {
    if (_.isNil(level1.order) || _.isNil(level2.order)) return

    if (level1.order < level2.order) return -1
    if (level1.order === level2.order) return 0
    if (level1.order > level2.order) return 1
  }
})

const levelsSlice = createSlice({
  name: 'levels',
  initialState: levelsAdapter.getInitialState(),
  extraReducers: createEntitiesReducer({ entitiesName: 'levels', adaptater: levelsAdapter })
})

export const { selectAll: getLevels } = levelsAdapter.getSelectors(_.get('levels'))

export const makeSearchLevels = () =>
  createSearchSelector(
    getLevels,
    (_state, params) => params,
    (levels, { isElementarySchool }) =>
      _.flow(
        filterEntitiesSimpleFieldByValues(
          'isElementarySchool',
          _.isNil(isElementarySchool) ? undefined : [isElementarySchool]
        )
      )(levels)
  )

export const searchLevels = makeSearchLevels()

export default levelsSlice.reducer
