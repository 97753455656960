import { useDispatch } from 'react-redux'

import { FloatingConnectToPremium } from 'connect/components/floatingTexts.jsx'
import CredentialsForm from 'connect/components/forms/credentials.jsx'
import ScreenContainer from 'connect/components/screenContainer.jsx'
import { resetUserForm } from 'connect/slices/userForm.jsx'

const Credentials = () => {
  const dispatch = useDispatch()

  return (
    <ScreenContainer variant='dark' backButton backPath='school_choice' onBackClick={() => dispatch(resetUserForm())}>
      <FloatingConnectToPremium />
      <CredentialsForm />
    </ScreenContainer>
  )
}

export default Credentials
