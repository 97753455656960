import _ from 'lodash/fp.js'
// Third modules
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'wouter'

// Our modules
import ScreenContainer from 'connect/components/screenContainer.jsx'

import UpdateUserInfosFormStep1 from 'connect/components/forms/updateUserInfos/step1.jsx'
import UpdateUserInfosFormStep2 from 'connect/components/forms/updateUserInfos/step2.tsx'
import Loader from 'connect/components/loader.jsx'
import { SCREEN_UPDATE_USER_INFOS } from 'connect/constants/screen.jsx'
import { useFetch } from 'connect/hooks/useFetch.jsx'

import { getCurrentUser } from 'connect/slices/auth.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import { firsStepRegistrationIsDone } from 'connect/utils/user.jsx'
import cssModule from './updateUserInfos.module.css'

const UpdateUserInfos = () => {
  const currentUser = useSelector(getCurrentUser)
  const isUserPremium = _.getOr(false, 'isPremium', currentUser)
  const [step, setStep] = useState(firsStepRegistrationIsDone(currentUser) ? 1 : 2)
  useFetch({ screen: SCREEN_UPDATE_USER_INFOS })

  useEffect(() => {
    setStep(firsStepRegistrationIsDone(currentUser) ? 1 : 2)
  }, [currentUser])

  return _.isEmpty(currentUser) ? (
    <Redirect to={getPath('home')} />
  ) : (
    <>
      <Loader screen={SCREEN_UPDATE_USER_INFOS} />

      <div className={cssModule.blueBackground} />
      <ScreenContainer
        className={cssModule.screenContainer}
        premiumLogo={isUserPremium}
        variant={isUserPremium ? 'dark' : 'light'}
        contentWidth='700px'
        widthAnimatedPictures={true}
      >
        {step === 1 ? (
          <UpdateUserInfosFormStep1 currentUser={currentUser} nextStepClick={() => setStep(2)} />
        ) : (
          <UpdateUserInfosFormStep2 currentUser={currentUser} stepBackClick={() => setStep(1)} />
        )}
      </ScreenContainer>
    </>
  )
}

export default UpdateUserInfos
