import _ from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getQueryParams } from '@lls/utils'
import TrialForm from 'connect/components/forms/trial.jsx'
import ScreenContainer from 'connect/components/screenContainer.jsx'
import { SCREEN_TRIAL_SUBSCRIPTION } from 'connect/constants/screen.jsx'
import { useFetch } from 'connect/hooks/useFetch.jsx'
import { searchBooks } from 'connect/slices/books.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { searchSubjects } from 'connect/slices/subjects.jsx'
import { generateTrialUser } from 'connect/slices/users.jsx'
import { getRedirectWithMedium } from 'connect/utils/routes.jsx'

import config from 'connect/config/index.js'
import cssModule from './trialSubscription.module.css'

const TrialSubscriptionScreen = () => {
  const dispatch = useDispatch()
  const { redirect, at_medium: medium, at_campaign: campaign } = getQueryParams(window.location)

  const redirectUrl = getRedirectWithMedium({ redirect: redirect || config.premium_url, medium, campaign })

  const books = useSelector(state => searchBooks(state, { hasNewDesign: true, isValid: true }))
  const subjects = useSelector(state => searchSubjects(state, { ids: _.flatMap('subjects', books) }))
  const schoolTypes = _.reject({ name: 'BTS' }, useSelector(getSchoolTypes))

  useFetch({ screen: SCREEN_TRIAL_SUBSCRIPTION })

  const onSubmit = useCallback(
    user => {
      dispatch(generateTrialUser({ user, redirect: redirectUrl }))
    },
    [dispatch, redirectUrl]
  )

  return (
    <div className={cssModule.screenContainer}>
      <ScreenContainer variant='dark' premiumLogo direction='row' contentWidth='500px'>
        <div className={cssModule.headerLeft}>
          <span className={cssModule.headerText}>Découvrez votre espace de démo !</span>
          <img className={cssModule.arrow} src='/assets/img/trial/arrow.svg' alt='arrow' />
          <img
            src='/assets/img/trial/trial-form.webp'
            alt='Formulaire demo manuel numérique'
            className={cssModule.backgroundImage}
          />
        </div>
        <div className={cssModule.content}>
          <h1 className={cssModule.title}>
            Commencez à tester la version
            <span className={cssModule.titleSpan}> Premium !</span>
          </h1>
          <TrialForm subjects={subjects} schoolTypes={schoolTypes} onSubmit={onSubmit} />
        </div>
      </ScreenContainer>
    </div>
  )
}

export default TrialSubscriptionScreen
