// Third modules
import { createAction, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash/fp.js'

const initialState = {
  school: null,
  level: null,
  book: null
}

export const setSchoolByUai = createAction('userForm/setSchoolByUai')

export const userFormSlice = createSlice({
  name: 'userForm',
  initialState,
  reducers: {
    mergeUserForm: (state, action) => {
      _.flow(_.get('payload'), _.pick(['school', 'book', 'level', 'schoolTypes', 'department']), obj =>
        Object.assign(state, obj)
      )(action)
    },
    resetUserForm: () => initialState
  }
})

export const getUserForm = _.get('userForm')

export const { mergeUserForm, resetUserForm } = userFormSlice.actions

export default userFormSlice.reducer
