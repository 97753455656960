import { Loader } from '@lls/lls-kit'
// Third modules
import { useSelector } from 'react-redux'

// Our modules
import { getIsLoading } from 'connect/slices/ui.jsx'

const LoaderComponent = ({ screen, params }) => {
  const isLoading = useSelector(state => getIsLoading(state, screen, params))
  return isLoading && <Loader />
}

export default LoaderComponent
