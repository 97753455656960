import _ from 'lodash/fp.js'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { getAllowedFormSchoolTypes } from '@lls/utils'
import { SchoolsSelector, StyledForm } from 'connect/components/forms/index.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { getUserForm } from 'connect/slices/userForm.jsx'

const OtherEntForm = () => {
  const userForm = useSelector(getUserForm)
  const schoolTypes = getAllowedFormSchoolTypes(useSelector(getSchoolTypes))

  const { control, formState, setValue } = useForm({
    defaultValues: {
      departments: _.getOr(null, 'school.department', userForm),
      schools: _.getOr(null, 'school.id', userForm)
    }
  })

  return (
    <StyledForm>
      <SchoolsSelector
        control={control}
        setValue={setValue}
        formState={formState}
        width='100%'
        schoolTypes={schoolTypes}
        disabled
      />
    </StyledForm>
  )
}

export default OtherEntForm
