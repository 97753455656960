import { createSearchSelector, filterEntitiesSimpleFieldByValues } from '@lls/utils'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { createEntitiesReducer } from './entities.jsx'

const wordsAdapter = createEntityAdapter()
const wordsSlice = createSlice({
  name: 'words',
  initialState: wordsAdapter.getInitialState(),
  extraReducers: createEntitiesReducer({ entitiesName: 'words', adaptater: wordsAdapter })
})

export const { selectAll: getWords, selectById: getWordById } = wordsAdapter.getSelectors(_.get('words'))

export const searchWords = createSearchSelector(
  getWords,
  (_state, params) => params,
  (words, { ids, bookUris }) => {
    return _.flow(
      filterEntitiesSimpleFieldByValues('id', ids),
      filterEntitiesSimpleFieldByValues('bookUri', bookUris)
    )(words)
  }
)

export const validateWord = createAction('users/validateWord')

export default wordsSlice.reducer
