import { createSearchSelector, filterEntitiesSimpleFieldByValues } from '@lls/utils'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { createEntitiesReducer } from './entities.jsx'

const booksAdapter = createEntityAdapter()
const booksSlice = createSlice({
  name: 'books',
  initialState: booksAdapter.getInitialState(),
  extraReducers: createEntitiesReducer({ entitiesName: 'books', adaptater: booksAdapter })
})

export const { selectAll: getBooks, selectById: getBookById } = booksAdapter.getSelectors(_.get('books'))

export const searchBooks = createSearchSelector(
  getBooks,
  (_state, params) => params,
  (books, { hasNewDesign, isValid, ids, uris }) => {
    return _.flow(
      filterEntitiesSimpleFieldByValues('id', ids),
      filterEntitiesSimpleFieldByValues('uri', uris),
      _.isNil(hasNewDesign) ? _.identity : filterEntitiesSimpleFieldByValues('hasNewDesign', [hasNewDesign]),
      _.isNil(isValid) ? _.identity : filterEntitiesSimpleFieldByValues('valid', [isValid])
    )(books)
  }
)

export default booksSlice.reducer
