import { gqlNoCache } from 'connect/api/graphql.jsx'
// Our modules
import { post } from 'connect/api/rest.jsx'
import { schoolFragment } from 'connect/api/school.tsx'
import { normalizeData } from 'connect/schema/index.jsx'
import { formatUserData } from 'connect/utils/user.jsx'
import _ from 'lodash/fp.js'

export const userFragment = `
  id
  isTrial
  isPremium
  isTeacher
  isStudent
  isReferent
  hasToUpdateInfos
  schools {
    ${schoolFragment}
  }
  profiles
  username
  gender
  firstname
  lastname
  hasContentNewsletter
  levels {
    id
  }
  subjects {
    id
  }
  schoolTypes
  booksV2 {
    id
    uri
  }
`

export const resetPassword = email =>
  post('users/resets/mails', {
    email
  })

export const loginUserWithUsernameAndPassword = ({ username, password }) =>
  gqlNoCache({
    query: `query connectLoginUserWithUsernameAndPassword ($username: String!, $password: String!) {
      auth(username: $username, password: $password) {
        token
        user {
          ${userFragment}
        }
      }
    }
    `,
    variables: {
      username,
      password
    }
  }).then(normalizeData)

export const fetchUser = ({ token }) =>
  gqlNoCache({
    query: `query connectFetchUser ($token: String!) {
      viewer(token: $token) {
        me {
          ${userFragment}
        }
      }
    }
`,
    variables: {
      token
    }
  }).then(normalizeData)

export const loginUserWithToken = ({ token }) =>
  gqlNoCache({
    query: `query connectLoginUserWithToken ($token: String!) {
      auth(token: $token) {
        token
        user {
          ${userFragment}
        }
      }
    }
  `,
    variables: {
      token
    }
  }).then(normalizeData)

export const generateTrialUser = ({ user }) =>
  gqlNoCache({
    query: `
      mutation ($user: TrialUserInputV2!) {
        viewer {
          generateTrialUser(user: $user) 
        }
      }
    `,
    variables: { user }
  }).then(_.get('viewer.generateTrialUser'))

export const updateUser = ({ user, token }) =>
  gqlNoCache({
    query: `
      mutation ($user: UserInput!, $token: String!) {
        viewer(token: $token) {
          updateUser(user: $user) {
            ${userFragment}
          }
        }
      }
    `,
    variables: {
      user: formatUserData(user),
      token
    }
  }).then(normalizeData)

export const loginUserWithEnt = ({ username, password, rne, relayState, connector }) =>
  post(`sso/connect?connector=${connector}`, { username, password, rne, relayState })
