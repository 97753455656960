import _ from 'lodash/fp.js'
// Third modules
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'wouter'

import { getAllowedFormSchoolTypes } from '@lls/utils'
import { StyledError, errorCodeToErrorComponentMap } from 'connect/components/errors.jsx'
// Our modules
import { SchoolsSelector, StyledForm } from 'connect/components/forms/index.jsx'
import { ANALYTICS_EVENTS } from 'connect/constants/analytics.jsx'
import { ENT_CONNECT_ERROR_SCHOOL_NOT_PREMIUM } from 'connect/constants/ent.jsx'
import { sendAnalytics } from 'connect/slices/analytics.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { getSchoolById } from 'connect/slices/schools.jsx'
import { getUserForm, mergeUserForm } from 'connect/slices/userForm.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import { getConnectorPath, hasValidLicense } from 'connect/utils/school.jsx'

const SchoolChoiceForm = () => {
  const userForm = useSelector(getUserForm)
  const schoolTypes = getAllowedFormSchoolTypes(useSelector(getSchoolTypes))

  const { control, formState, watch, setValue } = useForm({
    defaultValues: {
      departments: _.getOr(null, 'school.department', userForm),
      schools: _.getOr(null, 'school.id', userForm),
      schoolTypes: _.getOr(null, 'school.schoolTypes', userForm)
    }
  })
  const dispatch = useDispatch()
  const schoolId = watch('schools')
  const department = watch('departments')
  const watchSchoolTypes = watch('schoolTypes')
  const [, setLocation] = useLocation()

  const school = useSelector(state => getSchoolById(state, schoolId))
  useEffect(() => {
    if (school || department || watchSchoolTypes) {
      dispatch(mergeUserForm({ school, department, schoolTypes: watchSchoolTypes }))
    }
    if (school && hasValidLicense(school)) {
      setLocation(getPath(getConnectorPath(school)))
    }
    if (school && !hasValidLicense(school)) {
      dispatch(sendAnalytics({ event: ANALYTICS_EVENTS.ACTIVATION_FAILED, schoolId: _.get('id', school) }))
    }
  }, [school, department, watchSchoolTypes, setLocation, dispatch])

  return (
    <StyledForm>
      <SchoolsSelector
        control={control}
        setValue={setValue}
        formState={formState}
        width='100%'
        schoolTypes={schoolTypes}
      />
      {school && !hasValidLicense(school) && (
        <StyledError>{errorCodeToErrorComponentMap[ENT_CONNECT_ERROR_SCHOOL_NOT_PREMIUM]}</StyledError>
      )}
    </StyledForm>
  )
}

export default SchoolChoiceForm
