// Third modules

import { useLocation } from 'wouter'

import { BigEcoleDirecteButton, BigEntButton } from 'connect/components/button/index.jsx'
import { ConnectText, ConnectTextHomeResponsive, PremiumTitle } from 'connect/components/common.jsx'
import { FloatingConnectToFree, FloatingConnectToPremium } from 'connect/components/floatingTexts.jsx'
import LoginForm from 'connect/components/forms/login.jsx'
// Our modules
import ScreenContainer from 'connect/components/screenContainer.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import cssModule from './home.module.css'

const Home = () => {
  const [, setLocation] = useLocation()

  return (
    <ScreenContainer variant='mixed'>
      <FloatingConnectToPremium />
      <FloatingConnectToFree />
      <PremiumTitle />
      <div className={cssModule.buttonsContainer}>
        <BigEntButton onClick={() => setLocation(getPath('school_choice'))} />
        <BigEcoleDirecteButton onClick={() => setLocation(getPath('statim'))} />
      </div>
      <div>
        <ConnectText className={cssModule.screenHomeConnectText} />
        <ConnectTextHomeResponsive className={cssModule.screenHomeConnectTextResponsive} />
      </div>
      <LoginForm />
    </ScreenContainer>
  )
}

export default Home
