import { createSearchSelector, filterEntitiesSimpleFieldByValues } from '@lls/utils'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { createEntitiesReducer } from './entities.jsx'

const subjectsAdapter = createEntityAdapter({
  sortComparer: (subject1 = {}, subject2 = {}) => {
    if (_.isNil(subject1.name) || _.isNil(subject2.name)) return

    return subject1.name.localeCompare(subject2.name)
  }
})

const subjectsSlice = createSlice({
  name: 'subjects',
  initialState: subjectsAdapter.getInitialState(),
  extraReducers: createEntitiesReducer({ entitiesName: 'subjects', adaptater: subjectsAdapter })
})

export const { selectAll: getSubjects } = subjectsAdapter.getSelectors(_.get('subjects'))

export const searchSubjects = createSearchSelector(
  getSubjects,
  (_state, params) => params,
  (subjects, { ids }) => {
    return _.flow(filterEntitiesSimpleFieldByValues('id', ids))(subjects)
  }
)

export default subjectsSlice.reducer
