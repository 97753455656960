import config from 'connect/config/index.js'
export const ENT_CONNECT_GAR_ERROR_NOT_AVAILABLE = 'ENT_CONNECT_GAR_ERROR_NOT_AVAILABLE'
export const ENT_CONNECT_ERROR_SCHOOL_NOT_PREMIUM = 'ENT_CONNECT_ERROR_SCHOOL_NOT_PREMIUM'
export const ENT_CONNECT_ERROR_CONNECTOR_NOT_SUPPORTED = 'ENT_CONNECT_ERROR_CONNECTOR_NOT_SUPPORTED'
export const ENT_CONNECT_STATIM_ERROR_SCHOOL_NOT_FOUND = 'ENT_CONNECT_STATIM_ERROR_SCHOOL_NOT_FOUND'
export const ENT_CONNECT_STATIM_ERROR_FAMILY_ACCOUNT = 'ENT_CONNECT_STATIM_ERROR_FAMILY_ACCOUNT'
export const ENT_CONNECT_STATIM_ERROR_LOGIN_API = 'ENT_CONNECT_STATIM_ERROR_LOGIN_API'
export const ENT_CONNECT_STATIM_ERROR_SCHOOLS_API = 'ENT_CONNECT_STATIM_ERROR_SCHOOLS_API'

export const ATRIUM_CONNECTOR_NAME = 'atrium-paca'
export const ENT_CONNECTOR_NAME = config.env === 'production' ? 'gar' : 'gar_TEST'
export const ECOLE_DIRECTE_CONNECTOR_NAME = 'statim'

export const ATRIUM = 'atrium'
export const ECOLEDIRECTE = 'ecole-directe'
export const ENT = 'ent'
export const IDENTIFIERS = 'identifiants-premium'
export const PRONOTE = 'pronote'

export const CONNECTIONS_CHOICES = [ATRIUM, ECOLEDIRECTE, ENT, IDENTIFIERS, PRONOTE]
