import { sortBooks } from '@lls/utils'
import _ from 'lodash/fp.js'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FloatingConnectToPremium } from 'connect/components/floatingTexts.jsx'
import EntForm from 'connect/components/forms/ent.jsx'
import LegalNotice from 'connect/components/legalNotice.jsx'
import ScreenContainer from 'connect/components/screenContainer.jsx'
import { SET_CHOOL_BY_UAI } from 'connect/constants/index.jsx'
import { SCREEN_ENT } from 'connect/constants/screen.jsx'
import { useFetch } from 'connect/hooks/useFetch.jsx'
import { searchBooks } from 'connect/slices/books.jsx'
import { getLevels } from 'connect/slices/levels.jsx'
import { getSubjects } from 'connect/slices/subjects.jsx'
import { getIsLoading } from 'connect/slices/ui.jsx'
import { getUserForm, resetUserForm } from 'connect/slices/userForm.jsx'

const EntScreen = () => {
  const dispatch = useDispatch()
  const levels = useSelector(getLevels)
  const subjects = useSelector(getSubjects)
  const books = useSelector(state => searchBooks(state, { hasNewDesign: true, isValid: true }))

  const filteredLevels = useMemo(() => {
    const bookLevels = _.flatMap('levels', books)
    return _.intersectionWith((a, b) => _.matches({ id: b }, a), levels, bookLevels)
  }, [levels, books])
  const sortedBooks = useMemo(() => sortBooks(books, levels, subjects), [books, levels, subjects])
  useFetch({ screen: SCREEN_ENT })

  const userForm = useSelector(getUserForm)
  const rne = _.get('school.rne', userForm)
  const isLoadingSchool = useSelector(state => getIsLoading(state, SET_CHOOL_BY_UAI, rne))
  const isLoadingScreenEnt = useSelector(state => getIsLoading(state, SCREEN_ENT))

  const isLoading = isLoadingSchool || isLoadingScreenEnt

  return (
    <ScreenContainer variant='dark' backButton backPath='school_choice' onBackClick={() => dispatch(resetUserForm())}>
      <FloatingConnectToPremium />
      {!isLoading && <EntForm levels={filteredLevels} subjects={subjects} books={sortedBooks} />}
      <LegalNotice />
    </ScreenContainer>
  )
}

export default EntScreen
