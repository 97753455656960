// Third modules
import _ from 'lodash/fp.js'

// Our modules
import axios from 'connect/api/axios.jsx'
import config from 'connect/config/index.js'

const handleResponse = ({ data }) => {
  if (data.errors) {
    throw data.errors[0]
  }
  return data.data
}

export const gqlNoCache = body => axios.post(`${config.api_url}/graph`, body).then(handleResponse)

const compressQuery = _.replace(/\s+/g, ' ')

export const gqlCached = ({ query, variables }) => {
  const condensedQuery = compressQuery(query)
  const stringifiedVariables = JSON.stringify(variables)
  return axios
    .get(
      `${config.api_url}/graph-cached?query=${condensedQuery}${
        stringifiedVariables ? `&variables=${stringifiedVariables}` : ''
      }`
    )
    .then(handleResponse)
}
