import cssModule from './floatingTexts.module.css'

export const FloatingConnectToPremium = () => (
  <div className={cssModule.leftFloatingContainer}>
    <p className={cssModule.leftFloatingText}>Connectez-vous</p>
    <p className={cssModule.leftFloatingText}>à votre compte</p>
    <p className={cssModule.leftFloatingPremiumText}>premium</p>
    <div className={cssModule.leftFloatingImgContainer}>
      <img src='/assets/img/home/red-arrow.svg' alt='flèche rouge' />
    </div>
  </div>
)

export const FloatingConnectToFree = ({
  position = 'right',
  primaryText = '',
  secondaryText = 'ou avec votre compte'
}) => (
  <div className={cssModule.rightFloatingContainer} data-position={position}>
    <p className={cssModule.rightFloatingText}>{primaryText}</p>
    <p className={cssModule.rightFloatingText}>{secondaryText}</p>
    <p className={cssModule.rightFloatingPremiumText}>Lelivrescolaire.fr</p>
    <div className={cssModule.rightFloatingImgContainer} data-position={position}>
      <img src='/assets/img/home/blue-arrow.svg' alt='' />
    </div>
  </div>
)
