import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '@lls/lls-kit'
import _ from 'lodash/fp.js'
import { useDispatch, useSelector } from 'react-redux'

// import { getSchoolById } from 'connect/slices/schools.jsx'
import { loginWithUsernameAndPassword } from 'connect/slices/users.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'

import { getAllowedFormSchoolTypes } from '@lls/utils'
import { StyledLink } from 'connect/components/common.jsx'
import FormNotifications from 'connect/components/formNotifications.jsx'
import { Password, SchoolsSelector, StyledForm, UserName } from 'connect/components/forms/index.jsx'
import SwitchConnection from 'connect/components/switchConnection.jsx'
import { IDENTIFIERS } from 'connect/constants/ent.jsx'
import { MODAL_FORGOTTEN_PASSWORD } from 'connect/constants/modal.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { openModal } from 'connect/slices/ui.jsx'
import { getUserForm } from 'connect/slices/userForm.jsx'
import cssModule from './credentials.module.css'

const CredentialsForm = () => {
  const dispatch = useDispatch()
  const schoolTypes = getAllowedFormSchoolTypes(useSelector(getSchoolTypes))

  const userForm = useSelector(getUserForm)

  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues: {
      departments: _.getOr(null, 'school.department', userForm),
      schools: _.getOr(null, 'school.id', userForm),
      username: '',
      password: ''
    }
  })

  const onSubmit = useCallback(
    data => {
      dispatch(loginWithUsernameAndPassword(_.pick(['username', 'password'], data)))
    },
    [dispatch]
  )

  const onPasswordForgottenClick = useCallback(
    () => dispatch(openModal({ name: MODAL_FORGOTTEN_PASSWORD })),
    [dispatch]
  )

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <div className={cssModule.titleContainer}>
        Identifiant <span>Premium</span>
      </div>

      <SchoolsSelector
        control={control}
        setValue={setValue}
        formState={formState}
        width='100%'
        schoolTypes={schoolTypes}
      />

      <UserName
        control={control}
        error={hasError('username', formState)}
        hint={getHint('username', formState)}
        label='Identifiant'
      />

      <Password control={control} error={hasError('password', formState)} hint={getHint('password', formState)} />

      <p>
        <StyledLink onClick={onPasswordForgottenClick}>Mot de passe oublié ?</StyledLink>
      </p>

      <Button type='submit' maxSize>
        {' '}
        Connexion{' '}
      </Button>

      <FormNotifications />

      <SwitchConnection connectionTo={IDENTIFIERS} />
    </StyledForm>
  )
}

export default CredentialsForm
