// Third modules
import _ from 'lodash/fp'
import { useForm } from 'react-hook-form'

import { Button } from '@lls/lls-kit'
import { filterEntitiesArrayFieldByValues, openWindow } from '@lls/utils'
// Our modules
import { SchoolTypes, Subjects } from 'connect/components/forms/index.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'

import { getPath } from 'connect/utils/routes.jsx'
import cssModule from './trial.module.css'

const TrialForm = ({ schoolTypes = [], subjects = [], onSubmit }) => {
  const { handleSubmit, control, formState, watch } = useForm({
    defaultValues: {
      schoolType: '',
      subjects: ''
    }
  })

  const watchedSchoolType = watch('schoolType')

  const hasSelectedPrimary = _.overSome(_.map(_.includes, ['Elémentaire', 'Maternelle']))(watchedSchoolType)

  return (
    <>
      <form className={cssModule.form} onSubmit={handleSubmit(onSubmit)}>
        <span className={cssModule.text}>J'enseigne</span>
        <SchoolTypes
          control={control}
          name='schoolType'
          rules={{ required: "Type d'établissement obligatoire" }}
          label="Type d'établissement *"
          error={hasError('schoolType', formState)}
          hint={getHint('schoolType', formState)}
          schoolTypes={schoolTypes}
          width='100%'
        />

        {!hasSelectedPrimary && (
          <>
            <Subjects
              className={cssModule.subjects}
              control={control}
              label='Matière *'
              error={hasError('subjects', formState)}
              hint={getHint('subjects', formState)}
              subjects={
                _.isEmpty(watchedSchoolType)
                  ? subjects
                  : filterEntitiesArrayFieldByValues('schoolTypes', [watchedSchoolType])(subjects)
              }
              searchable
              width='100%'
            />

            <Button type='submit'>C'est parti !</Button>
          </>
        )}
      </form>
      {hasSelectedPrimary && (
        <>
          <h3>Vous êtes professeur des écoles ?</h3>
          <p>
            L'expérience Premium est disponible uniquement sur les manuels de la 6e à la Tle, mais notre première
            collection de Primaire est actuellement en cours d'élaboration !
          </p>
          <p>
            Les premiers contenus seront bientôt disponibles, inscrivez-vous en cliquant ici pour rester informé de
            l'avancée du projet !
          </p>
          <Button
            href={getPath('nl')}
            onGoTo={({ href, target }) => openWindow(href, target)}
            target='_self'
            className={cssModule.button}
          >
            S'inscrire à la newsletter
          </Button>
        </>
      )}
    </>
  )
}

export default TrialForm
