import { getQueryParams } from '@lls/utils'
import { useDispatch } from 'react-redux'

import { PremiumTitle } from 'connect/components/common.jsx'
import { FloatingConnectToPremium } from 'connect/components/floatingTexts.jsx'
import OtherEntForm from 'connect/components/forms/otherEnt.jsx'
import OtherEntContent from 'connect/components/otherEnt/content.jsx'
import ScreenContainer from 'connect/components/screenContainer.jsx'
import { resetUserForm } from 'connect/slices/userForm.jsx'
import cssModule from './otherEnt.module.css'

const OtherEnt = () => {
  const { connector } = getQueryParams(window.location)
  const dispatch = useDispatch()

  return (
    <div className={cssModule.screenContainer}>
      <ScreenContainer variant='dark' backButton backPath='school_choice' onBackClick={() => dispatch(resetUserForm())}>
        <FloatingConnectToPremium />
        <PremiumTitle />
        <div className={cssModule.formContainer}>
          <OtherEntForm />
        </div>
        <OtherEntContent connector={connector} />
      </ScreenContainer>
    </div>
  )
}

export default OtherEnt
