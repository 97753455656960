import { Snackbar } from '@lls/lls-kit'
import _ from 'lodash/fp.js'
// Third modules
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SNACKBAR_NOTIFICATION } from 'connect/constants/index.jsx'
// Our modules
import { makeSearchNotifications, removeNotifications } from 'connect/slices/ui.jsx'

const map = _.map.convert({ cap: false })

const SnackbarNotification = ({ notification, onClose }) => {
  const handleClose = useCallback(() => {
    onClose(notification)
  }, [notification, onClose])

  const { params, title, message, severity } = notification || {}

  return <Snackbar open close={handleClose} severity={severity} message={message} title={params?.title ?? title} />
}

const Notifications = () => {
  const dispatch = useDispatch()

  const searchNotificationsSelector = useMemo(makeSearchNotifications, [])
  const notifications = useSelector(state => searchNotificationsSelector(state, { types: [SNACKBAR_NOTIFICATION] }))

  const onClose = useCallback(
    notification => {
      dispatch(removeNotifications(notification))
    },
    [dispatch]
  )

  return map(
    (notification, i) => <SnackbarNotification key={i} notification={notification} onClose={onClose} />,
    notifications
  )
}

export default Notifications
