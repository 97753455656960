import { Divider, Icon } from '@lls/lls-kit'
import _ from 'lodash/fp.js'

import { cx, makeComponent } from '@lls/utils'
import { isClean, isDirtyAndWithoutError } from 'connect/utils/form.jsx'
import cssModule from './common.module.css'

export const StyledLink = makeComponent('span', cssModule.link)

export const ConnectText = ({ className }) => (
  <div className={cx(className, cssModule.connectTextContainer)}>
    <Divider />
    <span className={cssModule.connectText}>Ou connectez-vous avec</span>
    <Divider />
  </div>
)

export const ConnectTextHomeResponsive = ({ className }) => (
  <div className={cx(className, cssModule.connectTextContainer)} data-home='1'>
    <Divider className={cssModule.divider} />
    <div className={cssModule.homeConnectText}>
      ou avec votre compte <br />
      <span className={cssModule.homeResponsive}>Lelivrescolaire.fr</span>
    </div>
  </div>
)

export const CheckMark = ({ formData = {}, fieldName, formState, watch }) => {
  const isShownCheckMark =
    ((!_.isEmpty(formData[fieldName]) && isClean(fieldName, formState)) ||
      isDirtyAndWithoutError(fieldName, formState)) &&
    (!_.isFunction(watch) ||
      (_.isFunction(watch) && !_.isEmpty(watch(fieldName))) ||
      (_.isFunction(watch) && _.isNumber(watch(fieldName)) && !_.isNil(watch(fieldName))))
  return <Icon className={cssModule.checkMarkIcon} data-visible={+isShownCheckMark} name='checkmarkfilled' />
}

export const PremiumTitle = () => (
  <div className={cssModule.title}>
    Connectez-vous à votre compte <br />
    <span className={cssModule.premiumTitle}>Premium</span>
  </div>
)

export const FreeTitle = () => (
  <div className={cssModule.title}>
    Continuez avec votre compte <br />
    Lelivrescolaire.fr
  </div>
)
