// Third modules

import { Button, Modal } from '@lls/lls-kit'
import cssModule from './resetPassword.module.css'

const ResetPasswordModal = ({ close }) => {
  return (
    <Modal title='Récupérer son mot de passe' closeModal={close}>
      <div className={cssModule.modalContent}>
        <span className={cssModule.text}>Nous vous avons envoyé le lien de récupération dans votre boîte mail 😊</span>
        <span className={cssModule.secondaryText}>(Pensez à vérifier les spams)</span>

        <Button maxSize onClick={close}>
          OK
        </Button>
      </div>
    </Modal>
  )
}

export default ResetPasswordModal
