import { createSearchSelector, filterEntitiesSimpleFieldByValues } from '@lls/utils'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { createEntitiesReducer } from './entities.jsx'

const schoolsAdapter = createEntityAdapter()

const schoolsSlice = createSlice({
  name: 'schools',
  initialState: schoolsAdapter.getInitialState(),
  extraReducers: createEntitiesReducer({ entitiesName: 'schools', adaptater: schoolsAdapter })
})

export const { selectAll: getSchools, selectById } = schoolsAdapter.getSelectors(_.get('schools'))
export const getSchoolById = (state, schoolId) => {
  if (!schoolId) return
  return selectById(state, schoolId)
}

export const searchSchools = createSearchSelector(
  getSchools,
  (_state, params) => params,
  (schools, { ids }) => filterEntitiesSimpleFieldByValues('id', ids)(schools)
)

export default schoolsSlice.reducer
