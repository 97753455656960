import { SCHOOL_TYPE_AUTRE } from '@lls/utils'
// Third modules
import _ from 'lodash/fp.js'

export const getConnectorPath = school => {
  const connector = _.get('connectors.0', school)
  switch (true) {
    case /^atrium/i.test(connector):
      return 'atrium'
    case /^gar|gar_test/i.test(connector):
      return 'gar'
    case /^statim/i.test(connector):
      return 'statim'
    case /^pronote/i.test(connector):
      return `/autre-ent?connector=${connector}`
    default:
      return 'credentials'
  }
}

export const hasValidLicense = _.flow(_.get('validLicenses'), _.negate(_.isEmpty))

export const hasNoFilterSchoolType = _.flow(
  _.remove(st => SCHOOL_TYPE_AUTRE === st),
  _.isEmpty
)
