import _ from 'lodash/fp.js'
// Third modules
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Button } from '@lls/lls-kit'

// Our modules
import { Word } from 'connect/components/forms/index.jsx'
import { validateWord } from 'connect/slices/words.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'
import cssModule from './classicAccess.module.css'

const ClassicAccessForm = ({ wordToGuess, redirect }) => {
  const dispatch = useDispatch()
  const { handleSubmit, control, formState } = useForm({ defaultValues: { word: '' } })

  const onSubmit = useCallback(
    data => {
      dispatch(validateWord({ word: _.get('word', data), bookUri: wordToGuess?.bookUri, redirect }))
    },
    [dispatch, wordToGuess, redirect]
  )

  return (
    <form className={cssModule.form} onSubmit={handleSubmit(onSubmit)}>
      {/* eslint-disable-next-line max-len */}
      <span className={cssModule.text}>
        Munissez-vous de l'œuvre : allez à la page {wordToGuess?.page}, puis trouvez la ligne n°{wordToGuess?.line}, et
        saisissez le {wordToGuess?.number}
        <sup>e</sup> mot.
      </span>
      <h2 className={cssModule.subtitle}>
        Attention - Il faut suivre la numérotation des lignes indiquée dans l’œuvre.
      </h2>
      <Word
        className={cssModule.word}
        width='100%'
        control={control}
        error={hasError('word', formState)}
        hint={getHint('word', formState)}
      />

      <Button type='submit' icon='arrowright' iconPosition='right'>
        Vérifier
      </Button>
    </form>
  )
}

export default ClassicAccessForm
