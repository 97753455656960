import { StyledLink } from 'connect/components/common.jsx'
import Youtube from 'connect/components/youtube.jsx'
import { PRONOTE } from 'connect/constants/ent.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import cssModule from './content.module.css'

const OtherEntContent = ({ connector }) => {
  switch (connector) {
    case PRONOTE:
      return (
        <div className={cssModule.container}>
          <span className={cssModule.text}>
            Pour vous connecter à votre compte Premium depuis Pronote, suivez les étapes de la vidéo ci-dessous ou
            accédez au tutoriel&nbsp;
            <StyledLink onClick={() => window.open(getPath('external_tuto_pronote'), '_blank')}>
              en cliquant ici.
            </StyledLink>
          </span>

          <Youtube src='https://www.youtube.com/embed/yOWyfA86MI4' />
        </div>
      )

    default:
      return (
        <div className={cssModule.container}>
          <span className={cssModule.text}>
            Vous rencontrez des difficultés pour vous connecter à votre compte Premium ? Contactez votre référent
            Lelivrescolaire.fr
          </span>
        </div>
      )
  }
}

export default OtherEntContent
