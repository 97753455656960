import { normalizeData } from 'connect/schema/index.jsx'
import { gqlNoCache } from './graphql.jsx'

export const sendCustomLogApi = ({ event, token, analyticsParams }) =>
  gqlNoCache({
    query: `mutation ($event: AnalyticsEvents!, $token: String, $analyticsParams: JSONObject) {
      marketing (token: $token) {
        createAnalytics(event: $event , analyticsParams: $analyticsParams) {
          status
        }
      }
    }`,
    variables: {
      token,
      event,
      analyticsParams
    }
  }).then(normalizeData)
