import _ from 'lodash/fp.js'
// Third modules
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DefaultError, StyledError, errorCodeToErrorComponentMap } from 'connect/components/errors.jsx'
import { FORM_NOTIFICATION } from 'connect/constants/index.jsx'
// Our modules
import { makeSearchNotifications, removeNotifications } from 'connect/slices/ui.jsx'

const FormNotifications = () => {
  const dispatch = useDispatch()

  const searchNotificationsSelector = useMemo(makeSearchNotifications, [])
  const formNotifications = useSelector(state => searchNotificationsSelector(state, { types: [FORM_NOTIFICATION] }))

  useEffect(() => () => dispatch(removeNotifications({ type: FORM_NOTIFICATION })), [dispatch])

  return (
    !_.isEmpty(formNotifications) && (
      <StyledError>
        {_.flow(_.last, _.get('message'), errorCode =>
          _.getOr(<DefaultError />, errorCode, errorCodeToErrorComponentMap)
        )(formNotifications)}
      </StyledError>
    )
  )
}

export default FormNotifications
