import { gqlNoCache } from './graphql.jsx'

export const validateWordApi = ({ word, bookUri, classicsToken, token }) =>
  gqlNoCache({
    query: `query connectValidateWordApi ($word: String!, $bookUri: String!, $classicsToken: String, $token: String) {
      marketing(token: $token) {
        validateWord(word: $word, bookUri: $bookUri, classicsToken: $classicsToken)
      }
    }`,
    variables: {
      word,
      bookUri,
      classicsToken,
      token
    }
  })

export const fetchAllowedBookUrisApi = ({ classicsToken }) =>
  gqlNoCache({
    query: `query connectFetchAllowedBookUrisApi ($classicsToken: String!) {
        marketing {
          getBookUrisFromToken(classicsToken: $classicsToken)
        }
      }`,
    variables: {
      classicsToken
    }
  })
