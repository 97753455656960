import { Button } from '@lls/lls-kit'
import _ from 'lodash/fp.js'
// Third modules
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'

import { CheckMark } from 'connect/components/common.jsx'
import { FirstName, Gender, LastName, Levels, StyledForm } from 'connect/components/forms/index.jsx'
import { searchLevels } from 'connect/slices/levels.jsx'
import { updateUserInfos } from 'connect/slices/users.jsx'
// Our modules
import { getHint, hasError } from 'connect/utils/form.jsx'
import { firsStepRegistrationIsDone } from 'connect/utils/user.jsx'
import cssModule from './updateUserInfos.module.css'

const UpdateUserInfosFormStep1 = ({ currentUser = {}, nextStepClick = _.noop }) => {
  const dispatch = useDispatch()
  const isStudent = _.get('isStudent', currentUser)
  const isUserPremium = _.getOr(false, 'isPremium', currentUser)
  const levels = useSelector(state => searchLevels(state, { isElementarySchool: false }))

  const { handleSubmit, control, formState, watch } = useForm({
    defaultValues: {
      ...currentUser,
      profiles: _.get('profiles.0', currentUser)
    }
  })

  const nextIsEnable = firsStepRegistrationIsDone(watch())
  const onSubmit = useCallback(
    (userInfos = {}) => {
      dispatch(
        updateUserInfos({
          userInfos: {
            ...userInfos,
            schoolTypes: userInfos.schoolTypes ? userInfos.schoolTypes : []
          }
        })
      )
    },
    [dispatch]
  )

  return (
    <div>
      <div className={cssModule.updateUserInfoTitle}>Complétez vos informations</div>
      <span className={cssModule.text}>Je suis</span>
      <div className={cssModule.subContainer}>
        <StyledForm>
          <div className={cssModule.fieldContainer}>
            <Gender control={control} width='100%' isStudent={isStudent} />
          </div>
          <div className={cssModule.fieldContainer2}>
            <div className={cssModule.subFieldContainer}>
              <LastName
                control={control}
                error={hasError('lastname', formState)}
                hint={getHint('lastname', formState)}
                width='100%'
              />

              <div className={cssModule.checkMark}>
                <CheckMark formData={currentUser} fieldName='lastname' formState={formState} watch={watch} />
              </div>
            </div>
            <div className={cssModule.subFieldContainer}>
              <FirstName
                control={control}
                error={hasError('firstname', formState)}
                hint={getHint('firstname', formState)}
                width='100%'
              />

              <div className={cssModule.checkMark}>
                <CheckMark formData={currentUser} fieldName='firstname' formState={formState} watch={watch} />
              </div>
            </div>
          </div>

          {isUserPremium && isStudent && (
            <div className={cssModule.fieldContainer}>
              <Levels
                control={control}
                error={hasError('levels', formState)}
                hint={getHint('levels', formState)}
                levels={levels}
                width='100%'
                clearable
              />

              <div className={cssModule.checkMark}>
                <CheckMark formData={currentUser} fieldName='levels' formState={formState} watch={watch} />
              </div>
            </div>
          )}
          <div className={cssModule.mandatoryText}>* Ces champs sont obligatoires.</div>
        </StyledForm>
        <div className={cssModule.buttonContainer}>
          <Button
            type='submit'
            onClick={formState.isDirty || isStudent ? handleSubmit(onSubmit) : nextStepClick}
            icon='arrowright'
            iconPosition='right'
            disabled={nextIsEnable}
          >
            {isStudent ? 'Valider' : 'Suivant'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UpdateUserInfosFormStep1
