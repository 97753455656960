// Third modules
import { KIT_CLASSNAME_LIGHT_THEME, linariaThemeProps } from '@lls/lls-kit'
import { cx } from '@lls/utils'
import _ from 'lodash/fp.js'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route } from 'wouter'

// Our modules
import Analytics from 'connect/components/analytics.jsx'
import Help from 'connect/components/help.jsx'
import Loader from 'connect/components/loader.jsx'
import Modal from 'connect/components/modal/index.jsx'
import Notifications from 'connect/components/notifications.jsx'
import QueryParamSlicer from 'connect/components/queryParamSlicer.jsx'
import { SCREEN_GLOBAL } from 'connect/constants/index.jsx'
import routes from 'connect/routes.jsx'
import { checkToken, fetchAllowedBooks } from 'connect/slices/users.jsx'

import cssModule from './app.module.css'
import { useFetch } from './hooks/useFetch.jsx'

export const App = () => {
  const dispatch = useDispatch()

  //we want the effect to only run once at start, because we could then be redirected to userUpdateInfos with a valid token
  useEffect(() => {
    dispatch(checkToken())
    dispatch(fetchAllowedBooks())
  }, [dispatch])

  useFetch({ screen: SCREEN_GLOBAL })

  return (
    <>
      {/* QueryParamSlicer must stay first, to make query params available for other components */}
      <QueryParamSlicer />
      <div
        {...linariaThemeProps({ isDysMode: false, sizeIndex: 2 })}
        className={cx(KIT_CLASSNAME_LIGHT_THEME, cssModule.accessibilityElement)}
      >
        {_.map(
          route => (
            <Route key={route.path} path={route.path} component={route.component} />
          ),

          routes
        )}

        <Help />
        <Modal />
        <Loader screen={SCREEN_GLOBAL} />
        <Notifications />
        <Analytics />
      </div>
    </>
  )
}
// trigger