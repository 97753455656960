import _ from 'lodash/fp.js'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@lls/lls-kit'
import { getAllowedFormSchoolTypes } from '@lls/utils'
import { PremiumTitle } from 'connect/components/common.jsx'
import FormNotifications from 'connect/components/formNotifications.jsx'
import { SchoolsSelector, StyledForm, StyledFormLogo } from 'connect/components/forms/index.jsx'
import SwitchConnection from 'connect/components/switchConnection.jsx'
import { ATRIUM, ATRIUM_CONNECTOR_NAME } from 'connect/constants/ent.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { getUserForm } from 'connect/slices/userForm.jsx'
import { loginWithEnt } from 'connect/slices/users.jsx'

const AtriumForm = () => {
  const dispatch = useDispatch()
  const schoolTypes = getAllowedFormSchoolTypes(useSelector(getSchoolTypes))

  const userForm = useSelector(getUserForm)

  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues: {
      departments: _.getOr(null, 'school.department', userForm),
      schools: _.getOr(null, 'school.id', userForm),
      schoolTypes: _.getOr([], 'schoolTypes', userForm)
    }
  })

  const onSubmit = useCallback(
    ({ schools }) => {
      dispatch(
        loginWithEnt({
          schoolId: schools,
          connector: ATRIUM_CONNECTOR_NAME
        })
      )
    },
    [dispatch]
  )

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormLogo src='/assets/img/ent/atrium.svg' alt='atrium-logo' />

      <PremiumTitle />

      <SchoolsSelector
        control={control}
        setValue={setValue}
        formState={formState}
        width='100%'
        schoolTypes={schoolTypes}
      />

      <Button type='submit' maxSize>
        Connexion avec Atrium
      </Button>

      <FormNotifications />

      <SwitchConnection connectionTo={ATRIUM} />
    </StyledForm>
  )
}

export default AtriumForm
