// Third modules

import { Button } from '@lls/lls-kit'
import { useLocation } from 'wouter'

// Our modules
import { ATRIUM, ECOLEDIRECTE, ENT, IDENTIFIERS, PRONOTE } from 'connect/constants/ent.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import cssModule from './index.module.css'

export const EntButton = ({ onClick }) => {
  return (
    <div className={cssModule.buttonContainer} onClick={onClick}>
      <Button className={cssModule.entButton} icon='ent' />
      <p className={cssModule.buttonLabel}>ENT</p>
    </div>
  )
}

export const BigEntButton = ({ onClick }) => {
  return (
    <Button className={cssModule.bigEntButton} icon='ent' onClick={onClick} maxSize>
      Se connecter avec son ENT
    </Button>
  )
}

export const EcoleDirecteButton = ({ onClick }) => {
  return (
    <div className={cssModule.buttonContainer} onClick={onClick}>
      <Button className={cssModule.ecoleDirecteButton} icon='ecoledirecte' />
      <p className={cssModule.buttonLabel}>EcoleDirecte</p>
    </div>
  )
}

export const BigEcoleDirecteButton = ({ onClick }) => {
  return (
    <Button className={cssModule.bigEcoleDirecteButton} icon='ecoledirecte' onClick={onClick} maxSize>
      Se connecter avec EcoleDirecte
    </Button>
  )
}

export const AtriumButton = ({ onClick }) => {
  return (
    <div className={cssModule.buttonContainer} onClick={onClick}>
      <Button className={cssModule.atriumButton} icon='atrium' />
      <p className={cssModule.buttonLabel}>Atrium</p>
    </div>
  )
}

export const PronoteButton = ({ onClick }) => {
  return (
    <div className={cssModule.buttonContainer} onClick={onClick}>
      <Button className={cssModule.pronoteButton} icon='pronote' />
      <p className={cssModule.buttonLabel}>Pronote</p>
    </div>
  )
}

const CredentialsButton = ({ onClick }) => {
  return (
    <div className={cssModule.buttonContainer} onClick={onClick}>
      <Button icon='person' theme='gray' accent='main' />
      <p className={cssModule.buttonLabel}>Identifiants</p>
    </div>
  )
}

export const ConnectionButton = ({ choice }) => {
  const [, setLocation] = useLocation()

  const goToEnt = () => setLocation(getPath('gar'))
  const goToEcoleDirecte = () => setLocation(getPath('statim'))
  const goToAtrium = () => setLocation(getPath('atrium'))
  const goToPronote = () => setLocation(getPath('pronote'))
  const goToCredentials = () => setLocation(getPath('credentials'))

  switch (true) {
    case choice === ENT:
      return <EntButton onClick={goToEnt} />
    case choice === ECOLEDIRECTE:
      return <EcoleDirecteButton onClick={goToEcoleDirecte} />
    case choice === PRONOTE:
      return <PronoteButton onClick={goToPronote} />
    case choice === ATRIUM:
      return <AtriumButton onClick={goToAtrium} />
    case choice === IDENTIFIERS:
      return <CredentialsButton onClick={goToCredentials} />
  }
}
