// Third modules
import { Button, Modal } from '@lls/lls-kit'
import cssModule from './help.module.css'

const HelpModal = ({ close }) => {
  return (
    <Modal title='Informations de contact' closeModal={close}>
      <div className={cssModule.modalContent}>
        <p>
          Nous serons ravis de vous répondre à l'adresse mail{' '}
          <a href='mailto: numerique@lelivrescolaire.fr'>numerique@lelivrescolaire.fr</a>, ou par téléphone au{' '}
          <a href='tel: +33982212994'>+33 (0)9 82 21 29 94</a> pour tout besoin d'informations supplémentaires 😊
        </p>

        <Button accent='floating' onClick={close}>
          Fermer
        </Button>
      </div>
    </Modal>
  )
}

export default HelpModal
