import _ from 'lodash/fp.js'

import config from 'connect/config/index.js'
import { ROUTE } from 'connect/constants/routes.jsx'
import Atrium from 'connect/screens/atrium.jsx'
import ClassicAccess from 'connect/screens/classicAccess.jsx'
import Credentials from 'connect/screens/credentials.jsx'
import EcoleDirecte from 'connect/screens/ecoleDirecte.jsx'
import Ent from 'connect/screens/ent.jsx'
import FreeAccess from 'connect/screens/freeAccess.jsx'
// Our modules
import Home from 'connect/screens/home.jsx'
import OtherEnt from 'connect/screens/otherEnt.jsx'
import PremiumAccess from 'connect/screens/premiumAccess.jsx'
import SchoolChoice from 'connect/screens/schoolChoice.jsx'
import Token from 'connect/screens/token.jsx'
import TrialSubscription from 'connect/screens/trialSubscription.jsx'
import UpdateUserInfos from 'connect/screens/updateUserInfos.jsx'

export default _.compact([
  {
    path: ROUTE.HOME,
    component: Home
  },
  {
    path: ROUTE.SET_TOKEN,
    component: Token
  },
  {
    path: ROUTE.UPDATE,
    component: UpdateUserInfos
  },
  {
    path: ROUTE.SCHOOL_CHOICES,
    component: SchoolChoice
  },
  {
    path: ROUTE.ECOLE_DIRECT,
    component: EcoleDirecte
  },
  {
    path: ROUTE.ATRIUM,
    component: Atrium
  },
  {
    path: ROUTE.PREMIUM_LOGIN,
    component: Credentials
  },
  {
    path: ROUTE.ENT,
    component: Ent
  },
  {
    path: ROUTE.OTHER_ENT,
    component: OtherEnt
  },
  {
    path: ROUTE.PREMIUM_ACCESS,
    component: PremiumAccess
  },
  {
    path: ROUTE.FREE_ACCESS,
    component: FreeAccess
  },
  {
    path: ROUTE.CLASSIC_ACCESS,
    component: ClassicAccess
  },
  config.is_trial_enabled
    ? {
        path: ROUTE.TRIAL_SUBSCRIPTION,
        component: TrialSubscription
      }
    : undefined
])
