// Third modules
import { createListenerMiddleware } from '@reduxjs/toolkit'
import _ from 'lodash/fp.js'

// Our modules
import {
  checkTokenEffectCreator,
  fetchAllowedBooksEffectCreator,
  generateTrialUserEffectCreator,
  loginWithEntEffectCreator,
  loginWithTokenCreator,
  loginWithUsernameAndPasswordCreator,
  resetPasswordEffectCreator,
  updateUserInfosEffectCreator
} from './user.jsx'

import { sendAnalyticsEffectCreator } from './analytic.jsx'

import { validateWordEffectCreator } from './marketing.jsx'

import { setSchoolByUaiEffectCreator } from './userForm.jsx'

const listenerMiddleware = createListenerMiddleware({
  onError: error => {
    //eslint-disable-next-line
    console.error('uncaught effect error', error)
  }
})

_.forEach(listenerMiddleware.startListening, [
  resetPasswordEffectCreator,
  loginWithUsernameAndPasswordCreator,
  loginWithTokenCreator,
  updateUserInfosEffectCreator,
  setSchoolByUaiEffectCreator,
  loginWithEntEffectCreator,
  checkTokenEffectCreator,
  fetchAllowedBooksEffectCreator,
  sendAnalyticsEffectCreator,
  generateTrialUserEffectCreator,
  validateWordEffectCreator
])

export default listenerMiddleware
