// Third modules
import _ from 'lodash/fp.js'
import { useSelector } from 'react-redux'

import { PremiumTitle } from 'connect/components/common.jsx'
import { FloatingConnectToPremium } from 'connect/components/floatingTexts.jsx'
import SchoolChoiceForm from 'connect/components/forms/schoolChoice.jsx'
// Our modules
import ScreenContainer from 'connect/components/screenContainer.jsx'
import { SET_CHOOL_BY_UAI } from 'connect/constants/index.jsx'
import { SCREEN_SCHOOL_CHOICE } from 'connect/constants/screen.jsx'
import { useFetch } from 'connect/hooks/useFetch.jsx'
import { getIsLoading } from 'connect/slices/ui.jsx'
import { getUserForm } from 'connect/slices/userForm.jsx'
import { hasValidLicense } from 'connect/utils/school.jsx'

const SchoolChoice = () => {
  const userForm = useSelector(getUserForm)
  const school = _.get('school', userForm)
  const rne = _.get('rne', school)
  const isLoading = useSelector(state => getIsLoading(state, SET_CHOOL_BY_UAI, rne))
  const isSchoolEmptyOrPremium = !school || hasValidLicense(school)
  useFetch({ screen: SCREEN_SCHOOL_CHOICE })

  return (
    <ScreenContainer
      variant={isSchoolEmptyOrPremium ? 'dark' : 'mixed'}
      backButton
      connectButton={!isSchoolEmptyOrPremium}
    >
      <FloatingConnectToPremium />
      <PremiumTitle />
      {!isLoading && <SchoolChoiceForm />}
    </ScreenContainer>
  )
}

export default SchoolChoice
