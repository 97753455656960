import { getQueryParams } from '@lls/utils'
import _ from 'lodash/fp.js'
// Third modules
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { mergeAnalytics } from 'connect/slices/analytics.jsx'
// Our modules
import { mergeRelayState } from 'connect/slices/relayState.jsx'
import { setSchoolByUai } from 'connect/slices/userForm.jsx'

const QueryDispatcher = () => {
  const dispatch = useDispatch()
  const queryParams = getQueryParams(window.location)

  useEffect(() => {
    if (!_.isEmpty(queryParams)) {
      dispatch(mergeRelayState(queryParams))
      dispatch(mergeAnalytics(queryParams))
    }

    const uai = _.getOr(_.get('at_campaign', queryParams), 'uai', queryParams)
    if (uai) {
      dispatch(setSchoolByUai(uai))
    }
  }, [queryParams, dispatch])

  return null
}

export default QueryDispatcher
