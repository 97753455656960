import _ from 'lodash/fp.js'
import { useDispatch, useSelector } from 'react-redux'

import { FloatingConnectToPremium } from 'connect/components/floatingTexts.jsx'
import EcoleDirecteForm from 'connect/components/forms/ecoleDirecte.jsx'
import LegalNotice from 'connect/components/legalNotice.jsx'
import ScreenContainer from 'connect/components/screenContainer.jsx'
import { SET_CHOOL_BY_UAI } from 'connect/constants/index.jsx'
import { getIsLoading } from 'connect/slices/ui.jsx'
import { getUserForm, resetUserForm } from 'connect/slices/userForm.jsx'

const EcoleDirecte = () => {
  const dispatch = useDispatch()
  const userForm = useSelector(getUserForm)

  const rne = _.get('school.rne', userForm)
  const isLoading = useSelector(state => getIsLoading(state, SET_CHOOL_BY_UAI, rne))

  return (
    <ScreenContainer variant='dark' backButton backPath='school_choice' onBackClick={() => dispatch(resetUserForm())}>
      <FloatingConnectToPremium />
      {!isLoading && <EcoleDirecteForm />}
      <LegalNotice />
    </ScreenContainer>
  )
}

export default EcoleDirecte
