import _ from 'lodash/fp.js'
// Third modules
import { normalize, schema } from 'normalizr'

const word = new schema.Entity('words')
const user = new schema.Entity('users')
const school = new schema.Entity('schools')
const book = new schema.Entity('books')
const subject = new schema.Entity('subjects')
const level = new schema.Entity('levels')
const schoolType = new schema.Entity('schoolTypes')

user.define({
  schools: [school],
  subjects: [subject],
  levels: [level],
  schoolTypes: [schoolType],
  books: [book],
  booksV2: [book]
})

book.define({
  subjects: [subject],
  levels: [level],
  textbooks: [book],
  oldBook: book
})

export const querySchema = {
  auth: {
    user
  },
  marketing: {
    getRandomWord: word
  },
  viewer: {
    me: user,
    schoolTypes: { hits: [schoolType] },
    subjects: { hits: [subject] },
    levels: [level],
    schools: { hits: [school] },
    books: { hits: [book] },

    // mutations
    updateUser: user
  }
}

export const normalizeData = _.flow(data => normalize(data, querySchema))
