// Third modules
import _ from 'lodash/fp.js'

// Our modules
import { openWindow } from '@lls/utils'
import { fetchAllowedBookUrisApi } from 'connect/api/marketing.jsx'
import {
  generateTrialUser as generateTrialUserApi,
  loginUserWithEnt as loginUserWithEntApi,
  loginUserWithToken as loginUserWithTokenApi,
  loginUserWithUsernameAndPassword as loginUserWithUsernameAndPasswordApi,
  resetPassword as resetPasswordApi,
  updateUser as updateUserApi
} from 'connect/api/user.jsx'
import config from 'connect/config/config.js'
import { ANALYTICS_EVENTS } from 'connect/constants/analytics.jsx'
import {
  COOKIE_JWT,
  COOKIE_JWT_CLASSICS,
  FORM_NOTIFICATION,
  ORIGINS,
  SNACKBAR_NOTIFICATION
} from 'connect/constants/index.jsx'
import { MODAL_RESET_PASSWORD } from 'connect/constants/modal.jsx'
import { getAnalytics, sendAnalytics } from 'connect/slices/analytics.jsx'
import { getCurrentUser, getToken, setAuth } from 'connect/slices/auth.jsx'
import { setEntities } from 'connect/slices/entities.jsx'
import { getRelayState } from 'connect/slices/relayState.jsx'
import { getSchoolById, searchSchools } from 'connect/slices/schools.jsx'
import { addNotification, openModal } from 'connect/slices/ui.jsx'
import {
  checkToken as checkTokenAction,
  fetchAllowedBooks as fetchAllowedBooksAction,
  generateTrialUser as generateTrialUserAction,
  loginWithEnt as loginWithEntAction,
  loginWithToken as loginWithTokenAction,
  loginWithUsernameAndPassword as loginWithUsernameAndPasswordAction,
  resetPassword as resetPasswordAction,
  updateUserInfos as updateUserInfosAction
} from 'connect/slices/users.jsx'
import { getCookie, setCookie } from 'connect/utils/cookie.jsx'
import {
  checkRedirect,
  getPath,
  getRedirectWithMedium,
  injectToken,
  isClassicAccessRoute,
  isSetTokenRoute,
  isTrialSubscriptionRoute
} from 'connect/utils/routes.jsx'
import { shouldUpdateUserInfos } from 'connect/utils/user.jsx'
import { tryCatch } from './utils.jsx'

const manageRedirection = ({ user, shouldActivatePremium = false, registrationEnd = false }, listenerApi) => {
  const state = listenerApi.getState()
  const relayState = getRelayState(state)
  const analyticsParams = getAnalytics(state)
  const redirect = _.get('redirect', relayState)
  const origin = _.get('origin', relayState)
  const token = getToken(listenerApi.getState())

  const userSchools = searchSchools(listenerApi.getState(), { ids: _.get('schools', user) })
  const hasSchoolValidLicences = _.flow(_.flatMap('validLicenses'), _.some(Boolean))(userSchools)
  switch (true) {
    case isClassicAccessRoute(window.location.pathname):
    case isTrialSubscriptionRoute(window.location.pathname) && !(user?.isPremium || user?.isTrial):
      return
    case shouldUpdateUserInfos(user):
      return history.pushState(null, '', getPath('update_user_info', { query: { redirect } }))
    case !user?.isPremium && hasSchoolValidLicences && shouldActivatePremium:
      return history.pushState(null, '', getPath('premium_access'))
    case origin === ORIGINS.APP:
      return window.open(getPath('external_app', { token, ...relayState }), '_self')
    case !_.isEmpty(redirect) && checkRedirect(redirect): {
      const redirectWithToken = injectToken(token, redirect)
      return window.open(
        getRedirectWithMedium({
          redirect: redirectWithToken,
          campaign: analyticsParams?.at_campaign,
          medium: analyticsParams?.at_medium
        }),
        '_self'
      )
    }
    case user?.isPremium || user?.isTrial:
      return window.open(getPath('external_premium'), '_self')
    default: {
      return window.open(getPath('external_front', { registrationEnd }), '_self')
    }
  }
}

export const postAuth = ({ data, listenerApi }) => {
  const token = _.get('result.auth.token', data)
  const userId = _.get('result.auth.user', data)
  const entities = _.get('entities', data)
  const user = _.flow(_.get('users'), _.find({ id: userId }))(entities)

  listenerApi.dispatch(setAuth({ token, userId: _.get('id', user) }))
  listenerApi.dispatch(setEntities(entities))
  const origin = _.get('relayState.origin', listenerApi.getState())
  origin !== ORIGINS.APP && setCookie(COOKIE_JWT, token)

  manageRedirection({ user }, listenerApi)
}

export const resetPasswordEffect = async (action, listenerApi) => {
  const email = _.get('payload.email', action)

  await resetPasswordApi(email)

  listenerApi.dispatch(openModal({ name: MODAL_RESET_PASSWORD }))
}

export const loginWithUsernameAndPasswordEffect = async (action, listenerApi) => {
  const username = _.get('payload.username', action)
  const password = _.get('payload.password', action)

  const data = await loginUserWithUsernameAndPasswordApi({
    username,
    password
  })

  postAuth({ data, listenerApi })
}

export const loginWithTokenEffect = async (action, listenerApi) => {
  const iToken = _.get('payload.token', action)
  const data = await loginUserWithTokenApi({ token: iToken })

  postAuth({ data, listenerApi })
}

export const updateUserInfosEffect = async (action, listenerApi) => {
  const userInfos = _.get('payload.userInfos', action)
  const token = getToken(listenerApi.getState())

  if (_.isEmpty(token) || _.isEmpty(userInfos)) return
  const { hasNoContentNewsletter = false, ...userData } = userInfos
  const hasContentNewsletter = !hasNoContentNewsletter

  const entities = _.get(
    'entities',
    await updateUserApi({ user: { ...userData, hasToUpdateInfos: false, hasContentNewsletter }, token })
  )

  listenerApi.dispatch(setEntities(entities))
  listenerApi.dispatch(
    addNotification({
      name: 'updateUserInfosEffectSucess',
      message: 'Modification(s) enregistrée(s) !',
      severity: 'success',
      type: SNACKBAR_NOTIFICATION
    })
  )

  listenerApi.dispatch(sendAnalytics({ event: ANALYTICS_EVENTS.PROFILE_UPDATED }))

  const currentUser = getCurrentUser(listenerApi.getState())
  manageRedirection({ user: currentUser, shouldActivatePremium: true, registrationEnd: true }, listenerApi)
}

export const loginWithEntEffect = async (action, listenerApi) => {
  const { schoolId, username, password, connector } = _.getOr({}, 'payload', action)

  const relayState = getRelayState(listenerApi.getState())
  const school = getSchoolById(listenerApi.getState(), schoolId)
  listenerApi.dispatch(
    sendAnalytics({ event: ANALYTICS_EVENTS.ENT_CONNECTION_STARTED, schoolId, userSsoType: connector })
  )
  const { url } = await loginUserWithEntApi({ rne: _.get('rne', school), username, password, relayState, connector })
  window.open(url, '_self')
}

export const checkTokenEffect = async (_action, listenerApi) => {
  const token = getCookie(COOKIE_JWT)

  const redirect = _.get('redirect', getRelayState(listenerApi.getState()))
  const isComingFromLocalhost = /http:\/\/(127\.0\.0\.1|localhost)/.test(redirect)
  if (!token || isSetTokenRoute(window.location.pathname) || isComingFromLocalhost) return

  const data = await loginUserWithTokenApi({ token })
  postAuth({ data, listenerApi })
}

export const fetchAllowedBooksEffect = async (_action, listenerApi) => {
  const classicsToken = getCookie(COOKIE_JWT_CLASSICS)
  if (classicsToken) {
    const data = await fetchAllowedBookUrisApi({ classicsToken })
    const allowedBooks = _.get('marketing.getBookUrisFromToken', data)
    listenerApi.dispatch(setAuth({ allowedBooks }))
  }
}

export const generateTrialUserEffect = async action => {
  const user = action?.payload?.user || {}
  const redirect = action?.payload?.redirect

  const token = await generateTrialUserApi({
    user: {
      subjects: _.castArray(user.subjects),
      schoolType: user.schoolType
    }
  })
  setCookie(COOKIE_JWT, token)
  openWindow(redirect || config.premium_url, '_self')
}

export const resetPasswordEffectCreator = {
  actionCreator: resetPasswordAction,
  effect: tryCatch({ effect: resetPasswordEffect })
}

export const loginWithUsernameAndPasswordCreator = {
  actionCreator: loginWithUsernameAndPasswordAction,
  effect: tryCatch({ effect: loginWithUsernameAndPasswordEffect })
}

export const loginWithTokenCreator = {
  actionCreator: loginWithTokenAction,
  effect: tryCatch({ effect: loginWithTokenEffect })
}

export const updateUserInfosEffectCreator = {
  actionCreator: updateUserInfosAction,
  effect: tryCatch({ effect: updateUserInfosEffect })
}

export const loginWithEntEffectCreator = {
  actionCreator: loginWithEntAction,
  effect: tryCatch({
    effect: loginWithEntEffect,
    notificationType: FORM_NOTIFICATION,
    errorName: 'entEffectError'
  })
}

export const checkTokenEffectCreator = {
  actionCreator: checkTokenAction,
  effect: tryCatch({ silent: true, effect: checkTokenEffect })
}

export const fetchAllowedBooksEffectCreator = {
  actionCreator: fetchAllowedBooksAction,
  effect: tryCatch({ silent: true, effect: fetchAllowedBooksEffect })
}

export const generateTrialUserEffectCreator = {
  actionCreator: generateTrialUserAction,
  effect: tryCatch({ silent: true, effect: generateTrialUserEffect })
}
