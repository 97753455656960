import { Button } from '@lls/lls-kit'
// Third modules
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { useDispatch } from 'react-redux'

// Our modules
import { Email, StyledForm } from 'connect/components/forms/index.jsx'
import { resetPassword } from 'connect/slices/users.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'
import cssModule from './resetPassword.module.css'

const ResetPasswordForm = () => {
  const dispatch = useDispatch()

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      email: ''
    }
  })

  const onSubmit = useCallback(
    data => {
      const { email } = data
      dispatch(resetPassword({ email }))
    },
    [dispatch]
  )

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <span className={cssModule.text}>Cela arrive à tout le monde 😉</span>
      <span className={cssModule.text}>On vous envoie la marche à suivre par mail !</span>

      <Email control={control} error={hasError('email', formState)} hint={getHint('email', formState)} />

      <Button type='submit' maxSize>
        Envoyez-moi un lien de récupération
      </Button>
    </StyledForm>
  )
}

export default ResetPasswordForm
