export const SCREEN_GLOBAL = 'SCREEN_GLOBAL'
export const SET_CHOOL_BY_UAI = 'SET_CHOOL_BY_UAI'

export const SNACKBAR_NOTIFICATION = 'SNACKBAR_NOTIFICATION'
export const FORM_NOTIFICATION = 'FORM_NOTIFICATION'

export const COOKIE_JWT = 'jwt_token'
export const COOKIE_JWT_CLASSICS = 'jwt_classics'

export const COOKIE_FIRST_TIME_ACCESS = 'first_time_connected'

export const ASSETS_URL = 'https://assets.lls.fr'

export const ORIGINS = {
  APP: 'app'
}
