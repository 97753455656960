// Third modules
import _ from 'lodash/fp.js'
import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import usePrevious from 'connect/hooks/usePrevious.jsx'
// Our modules
import { loginWithToken } from 'connect/slices/users.jsx'
import cssModule from './token.module.css'

const Token = props => {
  const dispatch = useDispatch()

  const token = _.get('params.token', props)
  const previousToken = usePrevious(token)

  useEffect(() => {
    if (token !== previousToken) {
      dispatch(loginWithToken({ token }))
    }
  }, [token, previousToken, dispatch])

  return <div className={cssModule.message}>Connexion en cours... </div>
}

export default Token
