// Third modules
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { useDispatch } from 'react-redux'

import { StyledLink } from 'connect/components/common.jsx'
import ConnectButton from 'connect/components/connectButton.jsx'
// Our modules
import { Password, UserName } from 'connect/components/forms/index.jsx'
import { MODAL_FORGOTTEN_PASSWORD } from 'connect/constants/modal.jsx'
import { openModal } from 'connect/slices/ui.jsx'
import { loginWithUsernameAndPassword } from 'connect/slices/users.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'
import cssModule from './login.module.css'

const LoginForm = () => {
  const dispatch = useDispatch()

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  })

  const onSubmit = useCallback(
    data => {
      dispatch(loginWithUsernameAndPassword(data))
    },
    [dispatch]
  )

  const onPasswordForgottenClick = useCallback(
    () => dispatch(openModal({ name: MODAL_FORGOTTEN_PASSWORD })),
    [dispatch]
  )

  return (
    <form className={cssModule.form} onSubmit={handleSubmit(onSubmit)}>
      <UserName control={control} error={hasError('username', formState)} hint={getHint('username', formState)} />

      <Password control={control} error={hasError('password', formState)} hint={getHint('password', formState)} />

      <p>
        <StyledLink onClick={onPasswordForgottenClick}>Mot de passe oublié ?</StyledLink>
      </p>

      <ConnectButton type='submit' />
    </form>
  )
}

export default LoginForm
