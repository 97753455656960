import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Icon } from '@lls/lls-kit'
import _ from 'lodash/fp.js'
import { useDispatch, useSelector } from 'react-redux'

import { getAllowedFormSchoolTypes } from '@lls/utils'
import { PremiumTitle } from 'connect/components/common.jsx'
import FormNotifications from 'connect/components/formNotifications.jsx'
import { Books, Levels, SchoolsSelector, StyledForm } from 'connect/components/forms/index.jsx'
import SwitchConnection from 'connect/components/switchConnection.jsx'
import { ENT, ENT_CONNECTOR_NAME } from 'connect/constants/ent.jsx'
import { getBookById } from 'connect/slices/books.jsx'
import { mergeRelayState } from 'connect/slices/relayState.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { getSchoolById } from 'connect/slices/schools.jsx'
import { getUserForm } from 'connect/slices/userForm.jsx'
import { loginWithEnt } from 'connect/slices/users.jsx'
import { getHint, hasError } from 'connect/utils/form.jsx'
import cssModule from './ent.module.css'

const EntForm = ({ levels, books }) => {
  const dispatch = useDispatch()
  const schoolTypes = getAllowedFormSchoolTypes(useSelector(getSchoolTypes))
  const userForm = useSelector(getUserForm)
  const departments = _.getOr(null, 'school.department', userForm)
  const schools = _.getOr(null, 'school.id', userForm)
  const userFormlevels = _.getOr(null, 'level.id', userForm)

  const userFormBook = _.get('book', userForm)
  const userFormBookId = _.get('id', userFormBook)
  const isNewBook = _.get('oldBook', userFormBook)
  const search = { [isNewBook ? 'id' : 'oldBook']: userFormBookId }
  const userFormBooks = _.flow(_.find(search), _.get('id'))(books)

  const { handleSubmit, control, formState, setValue, watch } = useForm({
    defaultValues: {
      departments,
      schools,
      books: userFormBooks,
      levels: userFormlevels
    }
  })

  const schoolId = watch('schools')
  const school = useSelector(state => getSchoolById(state, schoolId))

  const onSubmit = useCallback(() => {
    dispatch(
      loginWithEnt({
        connector: ENT_CONNECTOR_NAME,
        schoolId: _.get('id', school)
      })
    )
  }, [dispatch, school])

  const filteringLevel = watch('levels')
  const filteredBooks = useMemo(() => {
    return filteringLevel ? _.filter(book => _.includes(filteringLevel, _.get('levels', book)), books) : books
  }, [books, filteringLevel])

  const bookId = watch('books')
  const book = useSelector(state => getBookById(state, bookId))
  useEffect(() => {
    dispatch(mergeRelayState({ bookId: _.get('id', book) }))
  }, [book, dispatch])

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <div className={cssModule.iconWrapper}>
        <Icon name='ent' width='25' height='25' />
      </div>
      <PremiumTitle />
      <SchoolsSelector
        control={control}
        setValue={setValue}
        formState={formState}
        defaultSchools={[userForm.school]}
        width='100%'
        schoolTypes={schoolTypes}
      />

      <Levels
        required={false}
        control={control}
        error={hasError('levels', formState)}
        hint={getHint('levels', formState)}
        levels={levels}
        width='100%'
      />

      <Books
        control={control}
        error={hasError('books', formState)}
        hint={getHint('books', formState)}
        books={filteredBooks}
        searchable
        width='100%'
      />

      <Button type='submit' maxSize>
        Connexion avec votre ENT
      </Button>

      <FormNotifications />
      <SwitchConnection connectionTo={ENT} />
    </StyledForm>
  )
}

export default EntForm
