const departments = [
  {
    id: 1,
    zipcode: '01',
    name: 'Ain',
    academy: 10
  },
  {
    id: 2,
    zipcode: '02',
    name: 'Aisne',
    academy: 20
  },
  {
    id: 3,
    zipcode: '03',
    name: 'Allier',
    academy: 6
  },
  {
    id: 4,
    zipcode: '04',
    name: 'Alpes-de-Haute-Provence',
    academy: 2
  },
  {
    id: 5,
    zipcode: '05',
    name: 'Hautes-Alpes',
    academy: 2
  },
  {
    id: 6,
    zipcode: '06',
    name: 'Alpes-Maritimes',
    academy: 23
  },
  {
    id: 7,
    zipcode: '07',
    name: 'Ardèche',
    academy: 8
  },
  {
    id: 8,
    zipcode: '08',
    name: 'Ardennes',
    academy: 19
  },
  {
    id: 9,
    zipcode: '09',
    name: 'Ariège',
    academy: 16
  },
  {
    id: 10,
    zipcode: '10',
    name: 'Aube',
    academy: 19
  },
  {
    id: 11,
    zipcode: '11',
    name: 'Aude',
    academy: 11
  },
  {
    id: 12,
    zipcode: '12',
    name: 'Aveyron',
    academy: 16
  },
  {
    id: 13,
    zipcode: '13',
    name: 'Bouches-du-Rhône',
    academy: 2
  },
  {
    id: 14,
    zipcode: '14',
    name: 'Calvados',
    academy: 5
  },
  {
    id: 15,
    zipcode: '15',
    name: 'Cantal',
    academy: 6
  },
  {
    id: 16,
    zipcode: '16',
    name: 'Charente',
    academy: 13
  },
  {
    id: 17,
    zipcode: '17',
    name: 'Charente-Maritime',
    academy: 13
  },
  {
    id: 18,
    zipcode: '18',
    name: 'Cher',
    academy: 18
  },
  {
    id: 19,
    zipcode: '19',
    name: 'Corrèze',
    academy: 22
  },
  {
    id: 20,
    zipcode: '20',
    name: 'Corse',
    academy: 26
  },
  {
    id: 22,
    zipcode: '21',
    name: "Côte-d'or",
    academy: 7
  },
  {
    id: 23,
    zipcode: '22',
    name: "Côtes-d'armor",
    academy: 14
  },
  {
    id: 24,
    zipcode: '23',
    name: 'Creuse',
    academy: 22
  },
  {
    id: 25,
    zipcode: '24',
    name: 'Dordogne',
    academy: 4
  },
  {
    id: 26,
    zipcode: '25',
    name: 'Doubs',
    academy: 3
  },
  {
    id: 27,
    zipcode: '26',
    name: 'Drôme',
    academy: 8
  },
  {
    id: 28,
    zipcode: '27',
    name: 'Eure',
    academy: 21
  },
  {
    id: 29,
    zipcode: '28',
    name: 'Eure-et-Loir',
    academy: 18
  },
  {
    id: 30,
    zipcode: '29',
    name: 'Finistère',
    academy: 14
  },
  {
    id: 31,
    zipcode: '30',
    name: 'Gard',
    academy: 11
  },
  {
    id: 32,
    zipcode: '31',
    name: 'Haute-Garonne',
    academy: 16
  },
  {
    id: 33,
    zipcode: '32',
    name: 'Gers',
    academy: 16
  },
  {
    id: 34,
    zipcode: '33',
    name: 'Gironde',
    academy: 4
  },
  {
    id: 35,
    zipcode: '34',
    name: 'Hérault',
    academy: 11
  },
  {
    id: 36,
    zipcode: '35',
    name: 'Ile-et-Vilaine',
    academy: 14
  },
  {
    id: 37,
    zipcode: '36',
    name: 'Indre',
    academy: 18
  },
  {
    id: 38,
    zipcode: '37',
    name: 'Indre-et-Loire',
    academy: 18
  },
  {
    id: 39,
    zipcode: '38',
    name: 'Isère',
    academy: 8
  },
  {
    id: 40,
    zipcode: '39',
    name: 'Jura',
    academy: 3
  },
  {
    id: 41,
    zipcode: '40',
    name: 'Landes',
    academy: 4
  },
  {
    id: 42,
    zipcode: '41',
    name: 'Loir-et-Cher',
    academy: 18
  },
  {
    id: 43,
    zipcode: '42',
    name: 'Loire',
    academy: 10
  },
  {
    id: 44,
    zipcode: '43',
    name: 'Haute-Loire',
    academy: 6
  },
  {
    id: 45,
    zipcode: '44',
    name: 'Loire-Atlantique',
    academy: 17
  },
  {
    id: 46,
    zipcode: '45',
    name: 'Loiret',
    academy: 18
  },
  {
    id: 47,
    zipcode: '46',
    name: 'Lot',
    academy: 16
  },
  {
    id: 48,
    zipcode: '47',
    name: 'Lot-et-Garonne',
    academy: 4
  },
  {
    id: 49,
    zipcode: '48',
    name: 'Lozère',
    academy: 11
  },
  {
    id: 50,
    zipcode: '49',
    name: 'Maine-et-Loire',
    academy: 17
  },
  {
    id: 51,
    zipcode: '50',
    name: 'Manche',
    academy: 5
  },
  {
    id: 52,
    zipcode: '51',
    name: 'Marne',
    academy: 19
  },
  {
    id: 53,
    zipcode: '52',
    name: 'Haute-Marne',
    academy: 19
  },
  {
    id: 54,
    zipcode: '53',
    name: 'Mayenne',
    academy: 17
  },
  {
    id: 55,
    zipcode: '54',
    name: 'Meurthe-et-Moselle',
    academy: 12
  },
  {
    id: 56,
    zipcode: '55',
    name: 'Meuse',
    academy: 12
  },
  {
    id: 57,
    zipcode: '56',
    name: 'Morbihan',
    academy: 14
  },
  {
    id: 58,
    zipcode: '57',
    name: 'Moselle',
    academy: 12
  },
  {
    id: 59,
    zipcode: '58',
    name: 'Nièvre',
    academy: 7
  },
  {
    id: 60,
    zipcode: '59',
    name: 'Nord',
    academy: 9
  },
  {
    id: 61,
    zipcode: '60',
    name: 'Oise',
    academy: 20
  },
  {
    id: 62,
    zipcode: '61',
    name: 'Orne',
    academy: 5
  },
  {
    id: 63,
    zipcode: '62',
    name: 'Pas-de-Calais',
    academy: 9
  },
  {
    id: 64,
    zipcode: '63',
    name: 'Puy-de-Dôme',
    academy: 6
  },
  {
    id: 65,
    zipcode: '64',
    name: 'Pyrénées-Atlantiques',
    academy: 4
  },
  {
    id: 66,
    zipcode: '65',
    name: 'Hautes-Pyrénées',
    academy: 16
  },
  {
    id: 67,
    zipcode: '66',
    name: 'Pyrénées-Orientales',
    academy: 11
  },
  {
    id: 68,
    zipcode: '67',
    name: 'Bas-Rhin',
    academy: 15
  },
  {
    id: 69,
    zipcode: '68',
    name: 'Haut-Rhin',
    academy: 15
  },
  {
    id: 70,
    zipcode: '69',
    name: 'Rhône',
    academy: 10
  },
  {
    id: 71,
    zipcode: '70',
    name: 'Haute-Saône',
    academy: 3
  },
  {
    id: 72,
    zipcode: '71',
    name: 'Saône-et-Loire',
    academy: 7
  },
  {
    id: 73,
    zipcode: '72',
    name: 'Sarthe',
    academy: 17
  },
  {
    id: 74,
    zipcode: '73',
    name: 'Savoie',
    academy: 8
  },
  {
    id: 75,
    zipcode: '74',
    name: 'Haute-Savoie',
    academy: 8
  },
  {
    id: 76,
    zipcode: '75',
    name: 'Paris',
    academy: 1
  },
  {
    id: 77,
    zipcode: '76',
    name: 'Seine-Maritime',
    academy: 21
  },
  {
    id: 78,
    zipcode: '77',
    name: 'Seine-et-Marne',
    academy: 24
  },
  {
    id: 79,
    zipcode: '78',
    name: 'Yvelines',
    academy: 25
  },
  {
    id: 80,
    zipcode: '79',
    name: 'Deux-Sèvres',
    academy: 13
  },
  {
    id: 81,
    zipcode: '80',
    name: 'Somme',
    academy: 20
  },
  {
    id: 82,
    zipcode: '81',
    name: 'Tarn',
    academy: 16
  },
  {
    id: 83,
    zipcode: '82',
    name: 'Tarn-et-Garonne',
    academy: 16
  },
  {
    id: 84,
    zipcode: '83',
    name: 'Var',
    academy: 23
  },
  {
    id: 85,
    zipcode: '84',
    name: 'Vaucluse',
    academy: 2
  },
  {
    id: 86,
    zipcode: '85',
    name: 'Vendée',
    academy: 17
  },
  {
    id: 87,
    zipcode: '86',
    name: 'Vienne',
    academy: 13
  },
  {
    id: 88,
    zipcode: '87',
    name: 'Haute-Vienne',
    academy: 22
  },
  {
    id: 89,
    zipcode: '88',
    name: 'Vosges',
    academy: 12
  },
  {
    id: 90,
    zipcode: '89',
    name: 'Yonne',
    academy: 7
  },
  {
    id: 91,
    zipcode: '90',
    name: 'Territoire de Belfort',
    academy: 3
  },
  {
    id: 92,
    zipcode: '91',
    name: 'Essonne',
    academy: 25
  },
  {
    id: 93,
    zipcode: '92',
    name: 'Hauts-de-Seine',
    academy: 25
  },
  {
    id: 94,
    zipcode: '93',
    name: 'Seine-Saint-Denis',
    academy: 24
  },
  {
    id: 95,
    zipcode: '94',
    name: 'Val-de-Marne',
    academy: 24
  },
  {
    id: 96,
    zipcode: '95',
    name: "Val-d'oise",
    academy: 25
  },
  {
    id: 97,
    zipcode: '976',
    name: 'Mayotte',
    academy: 34
  },
  {
    id: 98,
    zipcode: '971',
    name: 'Guadeloupe',
    academy: 29
  },
  {
    id: 99,
    zipcode: '973',
    name: 'Guyane',
    academy: 30
  },
  {
    id: 100,
    zipcode: '972',
    name: 'Martinique',
    academy: 28
  },
  {
    id: 101,
    zipcode: '974',
    name: 'Réunion',
    academy: 27
  },
  {
    id: 102,
    zipcode: '988',
    name: 'Nouvelle Calédonie',
    academy: 31
  },
  {
    id: 103,
    zipcode: '987',
    name: 'Polynésie Française',
    academy: 32
  },
  {
    id: 104,
    zipcode: '986',
    name: 'Wallis et Futuna',
    academy: 33
  },
  {
    id: 105,
    zipcode: '975',
    name: 'ST Pierre ET Miquelon',
    academy: 35
  },
  {
    id: 107,
    zipcode: '978',
    name: 'Saint-Martin'
  },
  {
    id: 106,
    zipcode: 'E99',
    name: 'Etranger',
    academy: 37
  }
]

export default departments
