import { createAction, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash/fp.js'

export const sendAnalytics = createAction('analytics/sendAnalytics')

const initialState = {
  origin: null,
  bookId: null,
  pageId: null,
  at_medium: null,
  at_campaign: null
}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    mergeAnalytics: (state, action) => {
      _.flow(
        _.get('payload'),
        _.pick(['origin', 'bookId', 'pageId', 'at_medium', 'at_campaign']),
        _.mapValues((value, key) => (key === 'bookId' || key === 'pageId' ? _.parseInt(value, 10) : value)),
        obj => Object.assign(state, obj)
      )(action)
    }
  }
})

export const getAnalytics = _.get('analytics')

export const { mergeAnalytics } = analyticsSlice.actions

export default analyticsSlice.reducer
