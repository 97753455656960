import { FreeTitle } from 'connect/components/common.jsx'
import { FloatingConnectToFree } from 'connect/components/floatingTexts.jsx'
import LoginForm from 'connect/components/forms/login.jsx'
import ScreenContainer from 'connect/components/screenContainer.jsx'

const FreeAccessScreen = () => {
  return (
    <ScreenContainer variant='light' backButton>
      <FloatingConnectToFree primaryText='Continuez avec' secondaryText='votre compte' />
      <FreeTitle />
      <LoginForm />
    </ScreenContainer>
  )
}

export default FreeAccessScreen
