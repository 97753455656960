// Third modules
import { Button } from '@lls/lls-kit'

// Our modules
import { StyledLink } from 'connect/components/common.jsx'
import { getPath } from 'connect/utils/routes.jsx'
import cssModule from './connectButton.module.css'

const ConnectButton = props => {
  return (
    <>
      <Button {...props} maxSize>
        Connexion
      </Button>

      <p>
        <span className={cssModule.text}>Vous n'avez pas de compte ? </span>
        <StyledLink onClick={() => window.open(getPath('external_registration'), '_blank')}>
          Inscrivez-vous !
        </StyledLink>
      </p>
    </>
  )
}

export default ConnectButton
