import { cx } from '@lls/utils'
import cssModule from './youtube.module.css'

const Youtube = ({ src, className }) => {
  return (
    <div className={cx(className, cssModule.container)}>
      <iframe
        className={cssModule.video}
        src={src}
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  )
}

export default Youtube
