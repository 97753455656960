// Third modules
import _ from 'lodash/fp.js'

// Our modules
import config from 'connect/config/index.js'
import { PRONOTE } from 'connect/constants/ent.jsx'
import { ROUTE } from 'connect/constants/routes.jsx'

export const getPath = (routeName, params = {}) => {
  switch (routeName) {
    case 'home':
      return ROUTE.HOME

    case 'update_user_info':
      return ROUTE.UPDATE

    case 'school_choice':
      return ROUTE.SCHOOL_CHOICES

    case 'external_front':
      return params?.registrationEnd ? `${config.front_url}?welcome_modal=true` : config.front_url

    case 'external_registration':
      return `${config.front_url}?open_registration=true`

    case 'external_premium':
      return config.premium_url

    case 'external_epub':
      return `${config.epub_url}/epub/${params.bookUri}`

    case 'external_resources':
      return `${
        params.localfqdn?.startsWith('http://localhost:') ? params.localfqdn : config.resources_url
      }/classiques/${params.bookUri}`

    case 'external_app':
      return `lls://commands/user-login${params.bookId || params.pageId ? '/resource-open' : ''}?token=${params.token}${
        params.bookId ? `&bookId=${params.bookId}` : ''
      }${params.pageId ? `&pageId=${params.pageId}` : ''}`

    case 'premium_access':
      return ROUTE.PREMIUM_ACCESS
    case 'atrium':
      return ROUTE.ATRIUM

    case 'gar':
      return ROUTE.ENT

    case 'statim':
      return ROUTE.ECOLE_DIRECT

    case 'pronote':
      return `${ROUTE.OTHER_ENT}?connector=${PRONOTE}`

    case 'credentials':
      return ROUTE.PREMIUM_LOGIN

    case 'free_access':
      return ROUTE.FREE_ACCESS

    case 'external_tuto_pronote':
      return 'https://helpdesk.lelivrescolaire.fr/fr/article/pronote-je-souhaite-acceder-a-mes-manuels-numeriques-premium-b5rgug/'

    case 'nl':
      return `${config.front_url}/newsletter`

    default:
      return routeName
  }
}

export const isSetTokenRoute = _.startsWith('/settoken')
export const isTrialSubscriptionRoute = _.startsWith('/essai-mnp')
export const isClassicAccessRoute = _.startsWith('/acces-classique')

export const injectToken = (token, redirect) => {
  // checkRedirect has been checked before
  return _.replace('{token}', token, redirect)
}

const HTTP_DOMAIN_WHITE_LIST = ['localhost', '127.0.0.1']

const HTTPS_DOMAIN_WHITE_LIST = ['.lls.fr', '.lelivrescolaire.fr']

export const checkRedirect = redirect => {
  try {
    const domain = new URL(redirect)

    if (domain.protocol === 'http:') return _.some(_.isEqual(domain.hostname), HTTP_DOMAIN_WHITE_LIST)

    if (domain.protocol === 'https:')
      return _.some(authorizedDomain => _.endsWith(authorizedDomain, domain.hostname), HTTPS_DOMAIN_WHITE_LIST)

    return false
  } catch (_error) {
    console.error('error parsing redirect')

    return false
  }
}

export const getRedirectWithMedium = ({ redirect, campaign, medium }) => {
  try {
    const newUrl = new URL(redirect)
    if (!newUrl.searchParams.has('at_medium') && !_.isEmpty(medium)) {
      newUrl.searchParams.set('at_medium', medium)
    }
    if (!newUrl.searchParams.has('at_campaign') && !_.isEmpty(campaign)) {
      newUrl.searchParams.set('at_campaign', campaign)
    }
    return newUrl.toString()
  } catch (_e) {
    console.error('error parsing redirect')
    return redirect
  }
}
