import { Button } from '@lls/lls-kit'
import _ from 'lodash/fp.js'
// Third modules
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { GradeLevel, Levels, SchoolTypes, SchoolsSelector } from '@lls/superkit'
import { useLevelFiltersForm } from '@lls/utils'
import { getAllowedFormSchoolTypes, getSubjectsBySchoolTypes, useUnderBreakpoint } from '@lls/utils'
import type { SchoolTypeName, Subject, User } from '@typings/models'
import { searchSchools as searchSchoolsApi } from 'connect/api/school.tsx'
import { CheckMark } from 'connect/components/common.jsx'
import { ContentNewsletter, Subjects } from 'connect/components/forms/index.jsx'
import { getLevels } from 'connect/slices/levels.jsx'
import { getSchoolTypes } from 'connect/slices/schoolTypes.jsx'
import { searchSchools } from 'connect/slices/schools.jsx'
import { getSubjects } from 'connect/slices/subjects.jsx'
import { updateUserInfos } from 'connect/slices/users.jsx'
// Our modules
import { getHint, hasError } from 'connect/utils/form.jsx'
import { useDispatch, useSelector } from 'react-redux'
import cssModule from './updateUserInfos.module.css'

const BREAKPOINT_MD = 1200

type FormValues = {
  email: string
  gradeLevel: 'primaire' | 'secondaire' | null
  subjects: Subject[]
  schoolTypes: SchoolTypeName[]
  levels: string[]
  schools: number
  profiles: string[]
}

const searchDenormalizedSchoolsApi = ({ department = '', ...props } = {}) =>
  department && searchSchoolsApi({ ...props, departments: _.castArray(department), normalized: false })

const UpdateUserInfosForm = ({
  currentUser = {},
  stepBackClick = _.noop
}: { currentUser: User; stepBackClick: () => void }) => {
  const dispatch = useDispatch()
  const schoolTypes = getAllowedFormSchoolTypes(useSelector(getSchoolTypes))
  const subjects = useSelector(getSubjects)
  const levels = useSelector(getLevels)
  const isTeacher = _.get('isTeacher', currentUser)
  const userSchools = useSelector(state => searchSchools(state, { ids: _.get('schools', currentUser) }))

  const { handleSubmit, control, formState, setValue, watch, register } = useForm<Partial<FormValues>>({
    defaultValues: {
      ...currentUser,
      gradeLevel: null,
      schoolTypes: [],
      profiles: _.get('profiles.0', currentUser),
      schools: undefined,
      subjects: [],
      levels: _.get('levels.0', currentUser) //only displayed for student
    }
  })

  const currentGradeLevel = watch('gradeLevel')
  const selectedSchoolTypesNames = watch('schoolTypes')
  const selectedLevelsName = watch('levels')

  const isMobile = useUnderBreakpoint(BREAKPOINT_MD)
  const formWidth = isMobile ? '240px' : '260px'
  const departments = isTeacher ? _.map('department', userSchools) : _.get('0.department', userSchools)
  const schools = isTeacher ? _.get('schools', currentUser) : _.get('schools.0', currentUser)

  const { filteredLevels, filteredSchoolTypes, subjectsWithoutPrimary } = useLevelFiltersForm({
    levels,
    subjects,
    schoolTypes,
    watchedSchoolTypeNames: selectedSchoolTypesNames,
    watchedGradeLevel: currentGradeLevel
  })

  const onSubmit = useCallback(
    (userInfos = {} as FormValues) => {
      dispatch(
        updateUserInfos({
          userInfos: {
            ...userInfos,
            schoolTypes: userInfos.schoolTypes ? userInfos.schoolTypes : []
          }
        } as any)
      )
    },
    [dispatch]
  )

  const formData = {
    ...currentUser,
    departments,
    schools: isTeacher ? _.compact(schools) : _.compact([schools]) // needed because no multiple props on schoolsSelector for student
  }

  const resetValues = () => {
    setValue('schoolTypes', [])
    setValue('levels', [])
    setValue('subjects', [])
    setValue('schools', null)
  }

  return (
    <div>
      <div className={cssModule.updateUserInfoTitle}>Complétez vos informations</div>
      <span className={cssModule.text}>J'enseigne à</span>
      <div className={cssModule.subContainerStep2}>
        <div className={cssModule.fields}>
          <div className={cssModule.fieldContainerStep2}>
            <GradeLevel
              label="Degré d'enseignement* :"
              formValueKey='gradeLevel'
              error={hasError('gradeLevel', formState)}
              onChangeExternal={resetValues}
              {...{ register, setValue, currentGradeLevel }}
            />
          </div>
        </div>
        <div className={cssModule.fields}>
          <div className={cssModule.fieldContainerStep2}>
            <SchoolTypes
              label="Type d'établissement*"
              multiple
              name='schoolTypes'
              valueProp='name'
              control={control}
              schoolTypes={filteredSchoolTypes}
              error={hasError('schoolTypes', formState)}
              hint={getHint('schoolTypes', formState)}
              rules={{ required: true }}
              width={formWidth}
              disabled={!currentGradeLevel}
            />
            <div className={cssModule.checkMarkStep2}>
              <CheckMark formData={formData} fieldName='schoolTypes' formState={formState} watch={watch} />
            </div>
          </div>
          <div className={cssModule.fieldContainerStep2}>
            <Levels
              label='Classe*'
              control={control}
              width={formWidth}
              levels={filteredLevels}
              error={hasError('levels', formState)}
              hint={getHint('levels', formState)}
              disabled={!selectedSchoolTypesNames || selectedSchoolTypesNames.length === 0}
            />
            <div className={cssModule.checkMarkStep2}>
              <CheckMark formData={formData} fieldName='levels' formState={formState} watch={watch} />
            </div>
          </div>
          {isTeacher && currentGradeLevel && currentGradeLevel !== 'primaire' && (
            <div className={cssModule.fieldContainerStep2}>
              <Subjects
                multiple
                control={control}
                name='subjects'
                subjects={
                  _.size(selectedSchoolTypesNames) === 1 && _.includes('Autre', selectedSchoolTypesNames)
                    ? subjectsWithoutPrimary
                    : getSubjectsBySchoolTypes(subjects, _.without(['Autre'], selectedSchoolTypesNames))
                }
                width={formWidth}
                hint={getHint('subjects', formState)}
                error={hasError('subjects', formState)}
                rules={{ required: false }}
                label='Matières enseignées*'
                disabled={Boolean(!selectedLevelsName || selectedLevelsName.length === 0)}
              />
              <div className={cssModule.checkMarkStep2}>
                <CheckMark formData={formData} fieldName='subjects' formState={formState} watch={watch} />
              </div>
            </div>
          )}
        </div>
        <h3 className={cssModule.h3}>Votre établissement</h3>
        <div className={cssModule.fieldContainerStep2}>
          <SchoolsSelector
            className={cssModule.schoolSelector}
            schoolFieldName='schools'
            departmentFieldName='department'
            schoolValue='id'
            setValue={setValue}
            formState={formState}
            width={formWidth}
            searchSchoolsApi={searchDenormalizedSchoolsApi}
            schoolTypes={selectedSchoolTypesNames}
            control={control}
            labels={{ department: 'Département*', schools: 'Établissement*' }}
          />
          <div className={cssModule.checkMarkSchool}>
            <CheckMark formData={formData} fieldName='schools' formState={formState} watch={watch} />
          </div>
        </div>
        <div className={cssModule.subtext}>
          Votre établissement n'apparaît pas dans la liste ?{' '}
          <a href='mailto:contact@lelivrescolaire.fr'>Contactez-nous</a>
        </div>
        <ContentNewsletter control={control} error={hasError('subjects', formState)} />
        <div className={cssModule.mandatoryText}>* Ces champs sont obligatoires.</div>
      </div>
      <div className={cssModule.buttonContainer}>
        <Button type='button' theme='gray' accent='floating' onClick={stepBackClick}>
          Précédent
        </Button>
        <Button type='submit' onClick={handleSubmit(onSubmit)}>
          Terminer l'inscription 🎉
        </Button>
      </div>
    </div>
  )
}

export default UpdateUserInfosForm
