import { normalizeData } from 'connect/schema/index.jsx'
// Our modules
import { gqlCached } from './graphql.jsx'

type SearchSchoolsProps = {
  departments?: string[]
  rnes?: string[]
  schoolTypes?: string[]
  normalized?: boolean
}

export const schoolFragment = `
  id
  name
  city
  zipcode
  address
  confirmed
  country
  rne
  prefix
  connectors
  department
  validLicenses {
    id
  }
`

export const searchSchools = ({ departments, rnes, schoolTypes, normalized = true }: SearchSchoolsProps) =>
  gqlCached({
    query: `
      query connectSearchSchools ($departments: [String], $rnes: [String],  $schoolTypes: [String]) {
        viewer {
          schools(departments: $departments, rnes: $rnes, schoolTypes: $schoolTypes, isConfirmed: true, first: 2500){
            hits {
              ${schoolFragment}
            }
          }
        }
      }
    `,
    variables: {
      departments,
      rnes,
      schoolTypes
    }
  }).then(result => (normalized ? normalizeData(result) : result?.viewer?.schools?.hits))
