import { getWindow } from '@lls/utils'
import { sendCustomLogApi } from 'connect/api/analytics.jsx'
import { COOKIE_JWT } from 'connect/constants/index.jsx'
import { getAnalytics, sendAnalytics as sendAnalyticsAction } from 'connect/slices/analytics.jsx'
import { getActivationStepFromPath, getInfoFromQueryParams, isUrlBlackListed } from 'connect/utils/analytics.jsx'
import { getCookie } from 'connect/utils/cookie.jsx'
import { tryCatch } from './utils.jsx'

export const sendAnalyticsEffect = ({ payload }, listenerApi) => {
  const { event, pageType, pageName, schoolId, userSsoType } = payload
  const token = getCookie(COOKIE_JWT)
  const piano = getWindow()?.pa
  const anonymousId = piano?.getVisitorId()
  const analyticsInfo = getAnalytics(listenerApi.getState())
  const pathname = window.location.pathname

  if (isUrlBlackListed(pathname)) return

  sendCustomLogApi({
    event,
    token,
    analyticsParams: {
      activationStep: getActivationStepFromPath(pathname),
      anonymousId,
      pageUrl: pathname,
      pageName,
      pageType,
      schoolId,
      userSsoType,
      ...getInfoFromQueryParams({ ...analyticsInfo, path: pathname })
    }
  })
}

export const sendAnalyticsEffectCreator = {
  actionCreator: sendAnalyticsAction,
  effect: tryCatch({ silent: true, effect: sendAnalyticsEffect })
}
