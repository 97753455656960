// Third modules
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash/fp.js'

// Our modules
import { getUsers } from './users.jsx'

const initialState = {
  token: null,
  userId: null,
  allowedBooks: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.token = _.get('payload.token', action)
      state.userId = _.get('payload.userId', action)
      state.allowedBooks = _.get('payload.allowedBooks', action)
    }
  }
})

export const getAuth = _.get('auth')

export const getCurrentUser = createSelector(getAuth, getUsers, (auth, users) =>
  _.find({ id: _.get('userId', auth) }, users)
)

export const getToken = _.flow(getAuth, _.get('token'))
export const getAllowedBooks = _.flow(getAuth, _.get('allowedBooks'))

export const { setAuth } = authSlice.actions

export default authSlice.reducer
