export const ROUTE = {
  HOME: '/',
  SET_TOKEN: '/settoken/:token',
  UPDATE: '/mise-a-jour',
  SCHOOL_CHOICES: '/choix-etablissement',
  ECOLE_DIRECT: '/ecole-directe',
  ATRIUM: '/atrium',
  PREMIUM_LOGIN: '/identifiants-premium',
  ENT: '/ent',
  OTHER_ENT: '/autre-ent',
  PREMIUM_ACCESS: '/acces-premium',
  FREE_ACCESS: '/acces-gratuit',
  TRIAL_SUBSCRIPTION: '/essai-mnp',
  CLASSIC_ACCESS: '/acces-classique/:uri'
}
