import _ from 'lodash/fp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { KIT_CLASSNAME_DARK_THEME, KIT_CLASSNAME_LIGHT_THEME } from '@lls/lls-kit'
import { cx, getQueryParams } from '@lls/utils'
import ClassicAccesForm from 'connect/components/forms/classicAccess.jsx'
import { ASSETS_URL } from 'connect/constants/index.jsx'
import { SCREEN_CLASSIC_ACCESS } from 'connect/constants/screen.jsx'
import { useFetch } from 'connect/hooks/useFetch.jsx'
import { getAllowedBooks, getCurrentUser } from 'connect/slices/auth.jsx'
import { searchBooks } from 'connect/slices/books.jsx'
import { getRelayState } from 'connect/slices/relayState.jsx'
import { searchWords } from 'connect/slices/words.jsx'
import { getPath } from 'connect/utils/routes.jsx'

import cssModule from './classicAccess.module.css'

const Cta = ({ inHeader = false }) => (
  <h1 className={cssModule.cta} data-in-header={+inHeader}>
    Débloquez vos ressources numériques !
  </h1>
)

const ClassicAccessScreen = props => {
  const uri = _.get('params.uri', props)
  const { localfqdn } = getQueryParams(window.location)
  const book = _.first(useSelector(state => searchBooks(state, { hasNewDesign: true, isValid: true, uris: [uri] })))
  const wordToGuess = _.first(useSelector(state => searchWords(state, { bookUris: [uri] })))

  const currentUser = useSelector(getCurrentUser)
  const allowedBooks = useSelector(getAllowedBooks)
  const redirect = useSelector(getRelayState)?.redirect || getPath('external_epub', { bookUri: uri, localfqdn })

  useEffect(() => {
    if (_.includes(uri, allowedBooks) || _.includes(book?.id, currentUser?.booksV2)) {
      window.open(redirect, '_self')
    }
  }, [allowedBooks, uri, currentUser, book, redirect])
  useFetch({ screen: SCREEN_CLASSIC_ACCESS, params: { uri } })

  return (
    <div className={cssModule.screenContainer}>
      <div
        style={{ '--connect-bgcolor': book?.classicColor }}
        className={cx(book?.isClassicTextDark ? KIT_CLASSNAME_LIGHT_THEME : KIT_CLASSNAME_DARK_THEME, cssModule.header)}
      >
        <div className={cssModule.headerContent}>
          <img
            className={cssModule.logo}
            src={
              book?.isClassicTextDark ? '/assets/img/logo-classics-black.svg' : '/assets/img/logo-classics-white.svg'
            }
            alt='logo-classic'
          />
          <Cta inHeader />
        </div>
        <img
          className={cssModule.headerVertImg}
          src={`${ASSETS_URL}/books/${book?.id}/header-vert.png`}
          alt='Header verticale'
        />
        <img
          className={cssModule.headerTabletImg}
          src={`${ASSETS_URL}/books/${book?.id}/header-tablet.png`}
          alt='Header tablet'
        />
      </div>
      <div className={cssModule.content}>
        <Cta />
        <h2 className={cssModule.title}>{book?.displayTitle}</h2>
        <h2 className={cssModule.subtitle}>
          Pour débloquer vos ressources numériques, nous avons besoin d'effectuer une petite vérification.
        </h2>
        <ClassicAccesForm wordToGuess={wordToGuess} redirect={redirect} />
      </div>
    </div>
  )
}

export default ClassicAccessScreen
