import { PRIMARY_SCHOOL_TYPES } from '@lls/utils'
// Third modules
import _ from 'lodash/fp.js'

export const formatUserData = _.flow(
  _.pick([
    'id',
    'schools',
    'levels',
    'subjects',
    'schoolTypes',
    'firstname',
    'lastname',
    'gender',
    'hasContentNewsletter'
  ]),
  userData => ({
    ...userData,
    schools: _.castArray(userData.schools),
    levels: _.castArray(userData.levels),
    subjects: _.castArray(userData.subjects),
    schoolTypes: _.castArray(userData.schoolTypes)
  })
)
export const isGarUser = _.flow(_.get('username'), _.overSome([_.startsWith('gar:'), _.startsWith('gar_TEST:')]))

export const getMandatoryParams = (user = {}) => {
  if (_.get('isTeacher', user)) {
    const primarySchoolTypesList = Object.values(PRIMARY_SCHOOL_TYPES)
    const hasPrimarySchoolType = user?.schoolTypes?.some(schoolType => primarySchoolTypesList.includes(schoolType))
    return hasPrimarySchoolType
      ? ['gender', 'firstname', 'lastname', 'schools', 'levels']
      : ['gender', 'firstname', 'lastname', 'schools', 'subjects', 'levels']
  }
  return ['firstname', 'lastname', 'schools', 'levels']
}

export const shouldUpdateUserInfos = (user = {}) => {
  if (user.isTrial) return false
  if (isGarUser(user)) return false
  if (_.get('hasToUpdateInfos', user)) return true
  const mandatoryParams = getMandatoryParams(user)
  return _.some(
    _.flow(param => _.get(param, user), _.isEmpty),
    mandatoryParams
  )
}

export const firsStepRegistrationIsDone = (userFormData = {}) => {
  const isStudent = _.get('isStudent', userFormData)
  const mandatoryParams = isStudent ? ['firstname', 'lastname', 'levels'] : ['firstname', 'lastname', 'gender']
  return _.some(
    _.flow(
      param => _.get(param, userFormData),
      param => (_.isNumber(param) ? _.castArray(param) : param),
      _.isEmpty
    ),
    mandatoryParams
  )
}
